import styled, { css } from 'styled-components';

interface ContainerPros {
  customization: any;
}

export const Conteiner = styled.div<ContainerPros>`
  p {
    font-size: 13px;
  }

  h3
  {
    color: black;
  }

  span {
    font-size: 18px;
    position: relative;
    top: 8px !important;
  }
  .action-button {
    margin-top: 6px !important;
    float: right;
    padding: 4px 30px;
  }
  textarea {
    padding: 8px;
    width: 100%;
    border-radius: 4px;
    border: none;
    height: 120px;
    color: #000;
  }
  opacity: 0.7;

  padding: 15px;
`;
