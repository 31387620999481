import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`
  .myClassname{
    border:0px;
  }

  margin-bottom: 25px;
`;
