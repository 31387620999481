export class Util
{
  /**
   * sleep
   */
  public static sleep(seconds:number) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    })
  }
}
