import React, { useState } from 'react';

import { Conteiner } from './style';
import Files from './Files';
import Questions from './Questions';
import Raffle from './Raffle';
import Survey from './Survey';
import Users from './Users';
import firebase from 'firebase';
import { useEvent } from '../../hooks/EventContext'

interface ModeratorProps {
  user: any;
  channelId: string
  eventId: string
};

const Moderator: React.FC<ModeratorProps> = (props: any) => {

  const eventContext = useEvent();
  const [tab, setTab] = useState(1);

  function updateScreen() {
    if (eventContext.channel) {
      eventContext.channel.update = !eventContext.channel.update;

      firebase.firestore().collection('channel').doc(eventContext.channel.id).update({
        update: !eventContext.channel.update
      })
    }
  }

  return (
    <Conteiner id="moderator" className="col-md-12 tab-pane fade in active">
      <ul className="nav nav-tabs">
        <li className="active">
          <a onClick={() => { setTab(1) }} data-toggle="tab" href={`#video`}>Vídeo</a>
        </li>
        <li>
          <a onClick={() => { setTab(2) }} data-toggle="tab" href={`#survey-manager`}>Enquetes</a>
        </li>
        <li>
          <a onClick={() => { setTab(3) }} data-toggle="tab" href={`#question-manager`}>Perguntas</a>
        </li>
        <li>
          <a onClick={() => { setTab(4) }} data-toggle="tab" href={`#user-manager`}>Usuários</a>
        </li>
        <li>
          <a onClick={() => { setTab(6) }} data-toggle="tab" href={`#raffle`}>Sorteios</a>
        </li>
        {/* <li>
          <a onClick={() => { setTab(5) }} data-toggle="tab" href={`#files`}>Arquivos</a>
        </li> */}
      </ul>
      <div className="tab-content">

        {tab == 1 && <div id="video">
          {!!eventContext.channel?.videoUrl && <div style={{ height: '420px' }}>
            <iframe className="jmvplayer" src={`${eventContext.channel?.videoUrl}${eventContext.channel?.videoUrl?.indexOf('?') > -1 ? '&update=' + eventContext.channel?.update : '?update=' + eventContext.channel?.update}`} allow="autoplay; fullscreen" frameBorder={0} width="100%" height="100%" ></iframe>
          </div>}
          <button className="btn btn-primary pull-right" style={{ marginTop: '10px' }} onClick={updateScreen}>Atualizar a tela dos usuários</button>
          <div style={{ clear: 'both' }}></div>
        </div>}
        {tab == 2 && <div id="survey-manager">
          <Survey {...props}></Survey>
        </div>}
        {tab == 3 && <div id="question-manager">
          <Questions {...props}></Questions>
        </div>}
        {tab == 4 && <div id="user-manager">
          <Users {...props}></Users>
        </div>}
        {tab == 5 && <div id="files">
          <Files {...props}></Files>
        </div>}
        {tab == 6 && <div id="raffle">
          <Raffle {...props}></Raffle>
        </div>}
      </div>
    </Conteiner>
  );
};

export default Moderator;
