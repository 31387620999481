import 'firebase/firestore';

import * as Yup from 'yup';

import { Conteiner, MessageBox, MessageList } from './style';
import React, { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../Button';
import { FiMessageCircle } from 'react-icons/fi';
import Image from './../../Image';
import { Picker } from 'emoji-mart';
import api from "../../../services/api";
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import getValidationErrors from '../../../utils/getValidationErrors';
import moment from 'moment';
import profilePicture from '../../../assets/images/profile-picture.png';
import { useAuth } from '../../../hooks/FirebaseAuth';
import { useForm } from "react-hook-form";
import { useToast } from '../../../hooks/Toast';
import { useTranslation } from 'react-i18next';

interface Props {
  user: any;
  channelId: string
  startConversation: String
};

interface IProfileMatch {
  id: string,
  channelId: string,
  eventId: string,
  createdAt: number,
  messages: IProfileMatchMessage[]
};

interface IProfileMatchMessage {
  uid: string,
  userName: string,
  userId: string,
  content: string,
  createdAt: number
};

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface ProfileMatchData {
  message: string;
}

const ProfileMatchList: React.FC<any> = (props) => {
  const [sessions, setSessions] = useState<any[]>([]);
  const [categories, setCategories] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [regions, setRegions] = useState<any[]>([]);
  const [category, setCategory] = useState("");
  const [company, setCompany] = useState("");
  const [region, setRegion] = useState("");
  const firestore = firebase.firestore();
  let started = {} as any;
  const [counter, setCounter] = useState<number>(0);
  const [chat$, setProfileMatch$] = useState<IProfileMatch>({} as IProfileMatch);
  const { getUser, updateUser } = useAuth();
  const [filtered, setFiltered] = useState<any[]>([]);
  const { addToast } = useToast();
  const [filter, setFilter] = useState<string>("");

  function loadUsers() {
    firestore.collection('session_manager').doc(props.channelId).get().then((snapshot) => {
      const doc = snapshot.data();
      if (doc) {
        // const users = asEnumerable(doc.users || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => !filter || u.email.toLowerCase().indexOf(filter) > -1).Take(200).ToArray();
        const users = asEnumerable(doc.users || []).OrderByDescending((u: any) => u.loginAt).ToArray();

        const categories = asEnumerable(users).Select((a: any) => a?.fields?.Categoria).Where(a => a?.trim()).Distinct().ToArray();
        setCategories(categories);

        const companies = asEnumerable(users).Select((a: any) => a?.fields?.Empresa).Where(a => a?.trim()).Distinct().ToArray();
        setCompanies(companies);

        const regions = asEnumerable(users).Select((a: any) => (a?.fields || {})["Em qual região possui interesse?"]).Where(a => a?.trim()).Distinct().ToArray();
        setRegions(regions);

        setSessions(users);
      }
    });
  }

  useEffect(() => {
    if (props.channelId) {

      if (!sessions.length) {
        loadUsers();
      }

      const check = (snapshot: any, index: number = 0) => {
        if (!started[index]) {
          started[index] = true;
        }
        else {
          const entities = snapshot.docs.map((a: any) => { return { id: a.id, ...a.data() } });
          if (entities.length && props.startConversation) {
            props.startConversation(asEnumerable(entities).OrderByDescending((a: any) => a.last).FirstOrDefault());
          }
        }
      }

      const options = {
        includeMetadataChanges: true
      };

      firestore.collection('conversation').where("user1", '==', props.user.id).onSnapshot(options, (snaptshot: any) => { check(snaptshot, 1) });
      firestore.collection('conversation').where("user2", '==', props.user.id).onSnapshot(options, (snaptshot: any) => { check(snaptshot, 2) });

      let unsubscribe = setInterval(loadUsers, 30000);
      return () => {
        if (unsubscribe) {
          clearInterval(unsubscribe);
        }
      }
    }
  }, [props.channelId]);



  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter >= 1) {
        setCounter(counter - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  });

  useEffect(() => {

    let users = asEnumerable(sessions || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => !filter || (u.name || u.email).toLowerCase().indexOf(filter) > -1).Take(200).ToArray();
    if (category) {
      users = asEnumerable(sessions || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => (u?.fields || {})["Categoria"] == category).ToArray();
    }

    if (company) {
      users = asEnumerable(sessions || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => (u?.fields || {})["Empresa"] == company).ToArray();
    }

    if (region) {
      users = asEnumerable(sessions || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => (u?.fields || {})["Em qual região possui interesse?"] == region).ToArray();
    }

    setFiltered(users);

    return () => {

    }
  }, [sessions, filter, category, region, company]);

  async function startConversation(from: any) {
    const { id, name, email } = props.user;
    let chat: any = undefined;
    const doc1 = await firestore.collection('conversation').where("user1", '==', id).where("user2", '==', from.userId).get();
    const doc2 = await firestore.collection('conversation').where("user1", '==', from.userId).where("user2", '==', id).get();
    const doc = doc1.docs.length ? doc1 : doc2;
    if (!doc.docs.length) {
      const data = {
        eventId: props.user.eventId,
        channelId: props.channelId,
        user1: id,
        user2: from.userId,
        userNames: [
          {
            id,
            name: name ||
              (props.user?.fields || {})["Nome completo"] ||
              (props.user?.fields || {})["Nome e sobrenome"] ||
              email?.split("@")[0]
          },
          {
            id: from.userId,
            name: from.name ||
              (from?.fields || {})["Nome completo"] || (from?.fields || {})["Nome e sobrenome"]
              || from.email?.split("@")[0]
          }],
        messages: [],
        createdAt: moment.utc().valueOf()
      };

      const newChat = await (await firestore.collection('conversation').add(data)).get();
      chat = { id: newChat.id, ...newChat.data() };
    }
    else {
      const docs = doc.docs.map((doc) => { return { id: doc.id, ...doc.data() } });
      chat = asEnumerable(docs).OrderByDescending((a: any) => a.last).FirstOrDefault();
    }

    setProfileMatch$(chat);

    if (props.startConversation) {
      props.startConversation(chat);
    }

  }

  return (
    <Conteiner id="profile-match" className="tab-pane out">
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane in active" id="participants" role="tabpanel" aria-labelledby="participants-tab">
          <div className="form-group">
            <div className="filters">
              <div className="selector">
                <select onChange={(e) => { setCategory(e.target.value) }}>
                  <option value="">Categoria</option>
                  {
                    asEnumerable(categories).OrderBy((f: any) => f).Select((value: any, i) => (
                      <option key={i} value={value}>{value}</option>
                    ))
                  }
                </select>
              </div>
              <div className="selector">
                <select onChange={(e) => { setCompany(e.target.value) }}>
                  <option value="">Empresa</option>
                  {
                    asEnumerable(companies).OrderBy((f: any) => f).Select((value: any, i) => (
                      <option key={i} value={value}>{value}</option>
                    ))
                  }
                </select>
              </div>
              <div className="selector">
                <select onChange={(e) => { setRegion(e.target.value) }}>
                  <option value="">Região</option>
                  {
                    asEnumerable(regions).OrderBy((f: any) => f).Select((value: any, i) => (
                      <option key={i} value={value}>{value}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <input className="form-control" style={{ display: 'inline-block', width: '95%', padding: '3px', margin: '5px' }} name="filter" onChange={(e) => { setFilter(e.target.value) }} type="text" placeholder="Filtre pelo nome" />
          </div>
          <MessageList id="messageList">
            {
              asEnumerable(filtered || []).Where(a => a.email != props.user.email).Take(100).Select((user: any, i?: number) => {
                return <p key={i || 0}>
                  <Image className="profile-picture" src={`https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fhypera-skincare%2Fprofile%2Fthumbs%2F${user.userId}_35x35.png?alt=media`} default={profilePicture} />
                  <span>{(user.name || user.email).split('@')[0].toUpperCase()}</span>  <i><button onClick={() => { startConversation(user); }}>Conversar</button></i>
                </p>
              }).ToArray()
            }
          </MessageList>
        </div>
      </div>
    </Conteiner>
  );
};

export default ProfileMatchList;
