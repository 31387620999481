import React from 'react';

import { AuthProvider } from './FirebaseAuth';
import { ToastProvider } from './Toast';
import { I18Provider } from './i18';
import { EventProvider } from './EventContext';
import { FirebaseProvider } from './Firebase';

const AppProvider: React.FC = ({ children, ...rest }) => (
  <FirebaseProvider>

    <ToastProvider>
      <I18Provider>
        <EventProvider {...rest}>
          <AuthProvider {...rest}>
            {children}
          </AuthProvider>
        </EventProvider>
      </I18Provider>
    </ToastProvider>

  </FirebaseProvider >

);

export default AppProvider;
