import 'firebase/firestore';

import React, { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import { Conteiner } from './style';
import { Link } from 'react-router-dom';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';

const Channels: React.FC<any> = (props: any) => {
  const eventContext = useEvent();
  const { t } = useTranslation();

  if (!props.internalPage) {
    document.body.style.backgroundColor = "transparent";
    document.body.style.overflow = "hidden";

    let jivo = document.body.getElementsByTagName("jdiv").item(0);
    if (jivo) {
      jivo.innerHTML = "";
    }
  }

  useEffect(() => {
    eventContext.loadInfo(props.params.event, props.params.channel);
  }, [props.params.event, props.params.channel]);

  return (
    <Conteiner id="Channels" customization={eventContext.event?.customization || {}}>
      {(!eventContext.event?.settings?.channels?.disabled && eventContext.channels?.length > 2) && <div id="room-container">
        <div className="room-header" style={{ backgroundColor: 'transparent', color: 'white' }}>
          <h4 style={{ margin: '10px' }}>{t('Salas ao Vivo')}</h4>
        </div>

        <div className="rooms">
          {asEnumerable(eventContext.channels || []).Select((channel: any, i) => (
            <div key={i} className="col-sm-3 col-xs-12 roomBox">
              <div className="room">
                {!channel.url && <Link target="_parent" to={'/' + eventContext.eventKey?.toLowerCase() + '/dashboard/' + channel.key}>
                  {channel.id == eventContext.channel.id && props.internalPage && <div className="overlay"><p>Você<br /> está aqui!</p></div>}
                  {!!channel.picture &&
                    <img className="bgImage" src={channel.picture} style={{ width: "100%", height: "auto" }} />}

                </Link>}
                {!!channel.url && <a target="_blank" href={channel.url || eventContext.eventKey?.toLowerCase() + '/dashboard/' + channel.key}>
                  {channel.id == eventContext.channel.id && props.internalPage && <div className="overlay"><p>Você<br /> está aqui!</p></div>}
                  {!!channel.picture &&
                    <img className="bgImage" src={channel.picture} style={{ width: "100%", height: "auto" }} />}
                </a>}
                {<div className="texto">
                  <div dangerouslySetInnerHTML={{ __html: channel.schedule?.replace(/\n/gi, '<br/>') || channel.description }}>
                  </div>
                </div>}
              </div>
            </div>
          ))}

          {eventContext.event?.activities?.enabled && asEnumerable(eventContext.event?.activities?.list || []).Select((activity: any, i) => (
            <div key={i} className="col-sm-3 col-xs-12 activities">
              <div className="title">
                {activity.name}
              </div>
              <div className="activity-list">
                {activity.list.map((item: any) => (
                  <div className="item">
                    <a target="_blank" href={item.link}>{item.name}</a>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>}
    </Conteiner>
  );
};

export default Channels;
