import 'firebase/firestore';
import 'firebase/firebase-storage';

import * as Yup from 'yup';

import { Button, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Comment from './Comment';
import { Conteiner } from './style';
import ReactCrop from 'react-image-crop';
import Spinner from 'react-bootstrap/Spinner';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import imgUpload from '../../assets/images/img-upload.png';
import moment from 'moment';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useForm } from 'react-hook-form';
import { useToast } from '../../hooks/Toast';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

interface Props {
  user: any;
  channelId: string
  eventId: string
};

const SocialNetwork: React.FC<Props> = (props: any) => {
  
  const eventContext = useEvent();
  const { t, i18n } = useTranslation();
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [feed, setFeed] = useState<any[]>([]);
  const [comments, setComments] = useState<any[]>([]);
  const [sending, isSending] = useState(false);

  const { getUser } = useAuth();
  const user = getUser();
  const [preImgLoad, setPreImgLoad] = useState<any>();
  const [imageRef, setImageRef] = useState<any>();
  const [croppedImageUrl, setCroppedImageUrl] = useState("");
  const [buffer, setBuffer] = useState<ArrayBuffer>();
  const [crop, setCrop] = useState<any>({
    aspect: 16 / 16
  });

  useEffect(() => {
    console.log(eventContext.event)
    const unsubscribe = firestore.collection("feed").where('eventId', '==', eventContext.event.id).orderBy("createdAt", "desc").onSnapshot((snapshot) => {
      const items = snapshot.docs.map(a => { return { ...a.data(), id: a.id } });
      setFeed(items);
    });
    return () => {
      unsubscribe();
    }
  }, []);

  const onSubmit = handleSubmit(async (data: any) => {
    try {

      isSending(true);
      const schema = Yup.object().shape({
        userText: Yup.string().required('O campo de mensagem é obrigatório'),
        userImg: Yup.string().required('A imagem é obrigatória')
      });
      await schema.validate(data, {
        abortEarly: true,
      });

      if (!data.userImg.length || !preImgLoad) {
        addToast({
          type: 'error',
          title: 'Erro no envio',
          description: 'É necessário selecionar uma imagem',
        });
        isSending(false);
        return;
      }

      let imgUrl: string = "";
      if (data.userImg.length) {
        //const uploaded: any = await firebase.storage().ref(`/companies/${props.params.event}/files/${v4()}`).put(data.userImg[0]);
        //imgUrl = await uploaded.ref.getDownloadURL() || "";
        imgUrl = await getPicture();
      }

      if (!imgUrl) {
        addToast({
          type: 'error',
          title: 'Nessário posicionar o recorte na imagem',
          description: 'Por favor, posicione o recorte na imagem antes de enviar',
        });
        isSending(false);
        return;
      }

      // wait 3 seconds to generate thumbnail
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 3000);
      });

      const entity: any = {
        createdAt: moment.utc().valueOf(),
        text: data.userText,
        channelId: eventContext.channel?.id || "",
        eventId: eventContext.event.id,
        user: {
          name: user.name,
          email: user.email,
          avatar: user.avatar_url || "",
        },
        uid: user.id || user.id
      };

      if (imgUrl) {
        entity.imgUrl = imgUrl;
      }

      await firestore.collection("feed").add(entity);

      setValue('userText', '');

      addToast({
        type: 'success',
        title: 'Post enviado',
        description: 'Sua mensagem foi enviada com sucesso!',
      });

      isSending(false);
      setPreImgLoad(undefined);

    } catch (error) {
      isSending(false);
      addToast({
        type: 'error',
        title: 'Erro no envio',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao fazer o envio, tente novamente.',
      });
    }
  });

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader1 = new FileReader();
      reader1.addEventListener('load', () => {
        setPreImgLoad(reader1.result);
      });
      reader1.readAsDataURL(e.target.files[0]);

      const reader2 = new FileReader();
      reader2.addEventListener('load', () => {
        setBuffer(reader2.result as ArrayBuffer);
      });
      reader2.readAsArrayBuffer(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  const onImageLoaded = (image: any) => {
    setImageRef(image);
  };

  const onCropComplete = (crop: any) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  async function makeClientCrop(crop: any) {
    if (imageRef && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageUrl(croppedImageUrl);
    }
  }

  const getCroppedImg = (image: any, crop: any, fileName: any): any => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = image.width;
    canvas.height = image.width;
    const ctx = canvas.getContext('2d');

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      image.width,
      image.width,
      crop.width,
      crop.height
    );

    return new Promise<any>((resolve, reject) => {
      canvas?.toBlob(async (blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        setBuffer(await blob.arrayBuffer());
        resolve(window.URL.createObjectURL(blob));
      }, 'image/jpeg');
    });
  }

  const handleCancel = () => {
    setPreImgLoad(undefined);
  };

  function like(feed: any) {
    const ref = firestore.collection("feed").doc(feed.id);
    feed.likesObj = feed.likesObj || {};

    if (!feed.likesObj[user.id]) {
      feed.likesObj = { ...feed.likesObj, [user.id]: true };
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: feed.likesObj
      });
    }
    else {
      delete feed.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: feed.likesObj
      });
    }
  }

  function commentLike(feed: any, comment: any) {
    const ref = firestore.collection("feed").doc(feed.id);
    comment.likesObj = comment.likesObj || {};
    comment.likes = comment.likes || 0;

    if (!comment.likesObj[user.id]) {
      comment.likesObj = { ...comment.likesObj, [user.id]: true };
      comment.likes++;
    }
    else {
      delete comment.likesObj[user.id];
      comment.likes--;
    }

    return ref.update({
      comments: feed.comments
    });
  }

  function textSubstring(str: string) {

    if (str.length > 150)
      return str.substring(0, 155) + '...';

    return str;
  }

  const getPicture = async () => {
    let imgUrl: string = "";
    if (!!buffer || !!preImgLoad) {
      console.log('teste'+props.params.event)
      const uploaded: any = await firebase.storage().ref(`/companies/${props.params.event?.toLowerCase()}/feed/${v4()}.png`).put(buffer || new Blob([preImgLoad as ArrayBuffer]), {
        contentType: 'image/png' // That's the event object from the Cloud Functions trigger
      });
      imgUrl = await uploaded.ref.getDownloadURL() || "";
      const extension = uploaded?.metadata?.contentType?.split('/')[1];
      imgUrl = imgUrl.replace("%2Ffeed%2F", '%2Ffeed%2Fthumbs%2F').replace('.' + extension, '_440x440').replace('_440x440', '_440x440.' + extension);
    }

    return imgUrl;
  }

  return (
    <Conteiner>



      <div style={{ margin: 'auto', maxWidth: 700, marginTop: 70 }}>
        <Form className="col-md-12" onSubmit={onSubmit}>
          <Form.Group>
            <div  className={!!((eventContext.channel?.description_languages || {})[i18n.language] ) ? "" : ""}>

              <h2 style={{ textAlign: 'center', paddingBottom: 50 }}>{t('Social Media')}</h2>

              <div className = "top-social-media" style={{ color: eventContext.event?.customization.buttonTextColor || '#FFFFFF', margin: 'auto', fontSize: 14, padding: '7px', backgroundColor: eventContext.event?.customization?.buttonBackgroundColor || '#A5232F', borderTopLeftRadius: 3, borderTopRightRadius: 3 }}>

                <b>{t('Create new post')}</b>
              </div>
            </div>
            <Form.Control style={{ borderRadius: 0 }} as="textarea" rows={3} name="userText" ref={register} placeholder={t('Leave your message here')} />

            {preImgLoad && (
              <ReactCrop
                src={preImgLoad as any}
                crop={crop}
                circularCrop={false}
                ruleOfThirds
                locked
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={onCropChange}
              />
            )}
            <input style={{ display: 'none' }} onChange={onSelectFile} id="custom-file" name="userImg" ref={register} type="file" />
            {!preImgLoad && <a style={{ cursor: 'pointer', textAlign: 'center', display: 'block', padding: 50, backgroundColor: 'white' }} onClick={() => { $('#custom-file').click() }}>
              <img src={imgUpload} />
            </a>}
          </Form.Group>

          {sending &&
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          }

          {!sending && <div style={{ textAlign: 'right' }}>
            <Button type="submit" style={{ margin: 10 }}>
            {t('Send') }<i className="fa fa-paper-plane"></i>
            </Button>
            {!!preImgLoad && <Button onClick={handleCancel}>
              Cancelar
            </Button>}
          </div>
          }
        </Form>
        <div style={{ clear: 'both' }}></div>
        {feed.map((item: any, i: number) => {
          return !!item.imgUrl && <div key={i} style={{ backgroundColor: 'white', margin: 20, padding: 0, color: 'black', position: 'relative' }}>
            <div className="col-sm-12 col-md-8" style={{ padding: 0 }}>
              <img style={{ width: '100%' }} src={item.imgUrl} />
            </div>
            <div className="col-md-4" style={{ margin: 0, padding: 0, position: 'unset' }}>
              <div style={{ padding: 15, paddingBottom: 0 }}>
                <b>{item.user.name}</b>
                <p style={{ wordBreak: 'break-word' }}>
                  {textSubstring(item.text)}<br />
                  <div style={{ textAlign: 'right' }}>
                    <em style={{ fontSize: '11px', color: '#ccc' }}>{moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss')}</em>
                  </div>
                </p>
              </div>
              <div style={{ maxHeight: 300, overflow: 'auto', padding: 15, paddingTop: 0, fontSize: 12, marginBottom: 10 }}>
                {asEnumerable(item.comments || []).OrderByDescending((a: any) => a?.createdAt || 0).Select((comment: any, i: any) => <React.Fragment key={i}>
                  <b>{comment.user.name}</b>
                  <br />
                  {comment.comment}<br />
                  <a style={{ marginRight: 10, float: 'left' }} onClick={() => commentLike(item, comment)}>
                    <i className="fa fa-heart text-danger" aria-hidden="true" />
                    &nbsp; <span className="text-muted">{comment.likes || 0} curtidas</span>
                  </a>
                  <div style={{ textAlign: 'right' }}>
                    <em style={{ fontSize: '11px', color: '#ccc' }}>{moment(comment.createdAt).format('DD-MM-YYYY HH:mm:ss')}</em>
                  </div>
                </React.Fragment>).ToArray()}
              </div>
              <div style={{ clear: 'both', height: 40 }}></div>

              <div style={{ position: 'absolute', width: 300, margin: 0, padding: 15, bottom: 0, right: 0, textAlign: 'right' }}>
                <a style={{ marginRight: 10 }} onClick={() => like(item)}>
                  <i className="fa fa-heart text-danger" aria-hidden="true" />
                  <span className="text-muted">{item.likes || 0}</span>
                </a>

                <Comment {...props} item={item} />

              </div>
            </div>
            <div style={{ clear: 'both' }}></div>
          </div>
        })}
        <div style={{ clear: 'both' }}></div>
      </div>
      <div style={{ clear: 'both' }}></div>


    </Conteiner>
  );
};

export default SocialNetwork;
