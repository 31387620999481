import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`
  p {
    font-size: 13px;
  }

  display: block;
  padding-bottom: 30px;

  .tab-content {
    color: black;
    background-color: white;
    padding: 15px;
  }

  a.dropdown-item {
    display: block;
    padding: 5px;
    cursor: pointer;
  }

`;
