import 'firebase/firestore';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Conteiner } from './style';
import api from '../../../services/api';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';

interface ChatProps {
  user: any;
  channelId: string
  eventId: string
};

const Users: React.FC<ChatProps> = (props: any) => {
  const firestore = firebase.firestore();
  const [sessions, setSessions] = useState<any[]>([]);
  const [filtered, setFiltered] = useState<any[]>([]);
  const { addToast } = useToast();
  const [filter, setFilter] = useState<string>("");
  const [count, setCount] = useState<number>(0);

  function loadUsers() {
    firestore.collection('session_manager').doc(props.channelId).get().then((snapshot) => {
      const doc = snapshot.data();
      if (doc) {
        // const users = asEnumerable(doc.users || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => !filter || u.email.toLowerCase().indexOf(filter) > -1).Take(200).ToArray();
        const users = asEnumerable(doc.users || []).OrderByDescending((u: any) => u.loginAt).ToArray();
        setSessions(users);
        setCount(doc.count);
      }
    });
  }

  useEffect(() => {
    if (props.channelId) {

      if (!sessions.length) {
        loadUsers();
      }

      let unsubscribe = setInterval(loadUsers, 30000);
      return () => {
        if (unsubscribe) {
          clearInterval(unsubscribe);
        }
      }
    }
  }, [props.channelId]);

  async function logoutUser(data: any) {

    console.log(data)
    // const ref = firestore.collection('logout').doc(props.eventId);

    // if ((await ref.get()).exists) {
    //   ref.update({
    //     uids: firebase.firestore.FieldValue.arrayUnion(data.userId)
    //   });
    // }
    // else {
    //   firebase.firestore().collection('logout').doc(props.eventId).set({
    //     uids: [data.userId]
    //   });
    // }

    api.post("/sessions/force-logout", { userId: data.userId });
  }

  useEffect(() => {

    const users = asEnumerable(sessions || []).OrderByDescending((u: any) => u.loginAt).Where((u: any) => !filter || u.email.toLowerCase().indexOf(filter) > -1).Take(200).ToArray();
    //const users = asEnumerable(doc.users || []).OrderByDescending((u: any) => u.loginAt).ToArray();
    setFiltered(users);

    return () => {

    }
  }, [sessions, filter]);

  return (
    <Conteiner>
      {/* <h3 style={{color: 'black'}}>Total ({count > 0 ? count : 0} usuários)</h3> */}
      {/* <div className="form-group">
        <input className="form-control" name="filter" onChange={(e) => { setFilter(e.target.value) }} type="text" placeholder="Filtro" />
      </div> */}
      <h3>Exibindo os 100 últimos acessos atualizado a cada 30 segundos</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Usuário</th>
            <th scope="col">Login</th>
            <th scope="col">Ações</th>
          </tr>
        </thead>
        <tbody>
          {filtered.map((s: any, index1: number) => (
            <tr key={index1}>
              <th scope="row">{sessions.length - index1}</th>
              <td style={{ textOverflow: 'ellipsis' }}>{s.email}</td>
              <td>{moment(s.loginAt).format('YYYY-MM-DD HH:mm:ss')}</td>
              <td>
                <DropdownButton id="dropdown-basic-button" title="Ações">
                  <Dropdown.Item onClick={() => logoutUser(s)}>Deslogar</Dropdown.Item>
                </DropdownButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

    </Conteiner>
  );
};

export default Users;
