import styled, { css, keyframes } from 'styled-components';

import { shade } from 'polished';
import videoBackground from '../../assets/images/video/D9uEenT.png';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`

${(props) => css`
color: ${props.customization.internalFontColor || props.customization.fontColor || '#333'};
`}

h1, h2, h3, h4, h5, h6 {
  ${(props) => css`
  color: ${props.customization.internalFontColor || props.customization.fontColor || '#FFFFFF'};
  `}
}

.dropdown-menu {
  background: #FFFFFF;
  /* a {
    ${(props) => css`
    color: ${props.customization.fontColor || '#333'};
    `}
  } */
  color: black;
}

a.link {
  ${(props) => css`
  color: ${props.customization.fontColor || '#333'};
  `}
}

padding-bottom: 0px;
margin-top: -60px;
padding-top: 30px;
//Marcos Adicionou
@media screen and  (max-width: 990px) {
  margin-top: -160px;
}

${(props) => css`
background: ${props.customization.backgroundInternal || `url(${videoBackground})`};
`}
background-repeat: no-repeat;
//background-size: 100%; //! Denys comentou
background-size: auto 100% //? Denys adicionou
background-position-x: center; //! Denys comentou
background-position-y: top; //! Denys comentou
background-position: top center; //? Denys adicionou
background-attachment: initial; // Marcos Voltou
//background-attachment: fixed; //? Denys adicionou
z-index: -10;

.desktop {
  display: block;
}
.mobile {
  display: none;
}

.navbar {
  ${(props) => css`
  background: ${props.customization.navbarBackground || 'rgba(190, 190, 190, 1)'}; //! Denys alterou 0.84 para 1
  //opacity: 0.85; //! Denys comentou
  opacity: 1; //! Denys adicionou
  `}
  /* min-height: 65px; */
}
.navbar-brand img {
  margin-top: -7px;
  height: 40px;
}
.navbar-text {
  font-size: 20px;
  font-weight: bold;
  ${(props) => css`
  color: ${props.customization.navbarTextColor || props.customization.fontColor || '#000'};
  `}
  font-style: italic;
}

.nav .open > a
{
  background-color: transparent;

}

#streaming {
  margin-top: 60px;
}

#streaming,
main {
  /*   background-color: red !important; */
  // overflow: hidden;
}
.clear {
  clear: both;
}

main > .col-md-8 h3 {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}
main > .col-md-8 h1 {
  font-size: 44px;
  font-weight: bold;
}
main > .col-md-8 p {
  /*   font-size: 17px; */
  font-weight: 500;
  text-align: right;
  line-height: 20px;
}
main > .col-md-8 {
  /*   background-color: white; */
  height: 100%;
}
main > .col-md-4 {
  /*   background-color: blue; */
  height: 100%;
}
.navbar-nav > li > a {
  ${(props) => css`
  color: ${props.customization.navbarTextColor || props.customization.fontColor || '#000'};
  `}
  opacity: 1;
  text-decoration: none;
}

.navbar-nav > li > a:hover {
  opacity: 0.6;
  background-color: transparent !important;
  text-decoration: none;
}

.navbar-nav > li > a:focus,
.navbar-nav > li > a:active,
.navbar-nav > li > a:hover {
  text-decoration: none;
  background-color: transparent;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 3px !important;
  border-radius: 1px;
  background: black;
}

article {
  margin: 20px 0;
}

p {
  font-size: 16px;
  font-weight: normal;
  line-height: 26px;
}

.profile-file {
  display: none;
}

.profile-picture {
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  margin-right: 5px;
  border-radius: 17px;
}

.profile-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

footer {
  margin-top: 40px;
}

@media screen and (max-width: 1200px) {
  #streaming {
    margin-top: 110px;
  }
}

#streamingFooter {
}

#streamingFooter img {
  max-height: 20vh !important;
  max-width: unset !important;
}

@media screen and (max-width: 767px) {

  .navbar-nav .open .dropdown-menu>li>a {
    ${(props) => css`
    color: ${props.customization.navbarTextColor || '#333'}; 
    `}
  }

  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  #streaming {
    margin-top: 60px;
  }

  #streamingFooter {
    height: 30vh !important;
  }

  #streamingFooter img {
    max-height: 30vh !important;
    max-width: auto !important;
  }

  #streaming,
  main {
    /* background-color: red !important; */
    // overflow: hidden;
    height: auto;
  }
  main > .col-md-8 h3 {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    margin-top: 20px;
  }
  main > .col-md-8 h1 {
    text-align: center;
    font-size: 30px;
    margin-top: 0;
  }
  main > .col-md-8 p {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    margin: 10px;
    margin-bottom: 40px;
  }

  .message-list {
    background-color: #f9f9f9;
    padding: 10px;
    overflow: auto !important;
    border: none !important;
    height: 332px;
    flex: none;
  }
  #apresentadores {
    margin: 0 auto !important;
  }

  #apresentadores .col-md-4 {
    width: 100%;
    float: left !important;
    display: block;
    margin-bottom: 20px;
  }
  #info {
    display: none;
  }

  .rooms img {
    max-height: 164px;
  }


  .rooms .col-md-3 {
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }
  body {
    padding-bottom: 0;
  }
  footer p {
    font-size: 14px;
    opacity: 0.7;
    margin-top: 10px;
  }

  #bb {
    position: fixed;
    top: 54px;
  }
}

background-size: cover;

${(props) => css`
${props.customization.customDashboardCss}
`}

`;



const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div<ContainerPros>`
  animation: ${appearFromLeft} 1s;

  /* footer */
  footer {
    display: flex;
    padding: 15px;

    ${(props) => css`
    ${props.customization?.footerCss};
    `}

    .footer-logo {
      padding-top: 10px;
    }

    .copyright {
      display: inline-block;
      padding: 15px;
      ${(props) => css`
      color: ${props.customization?.fontColor};
      `}
    }
  }
  footer img {
    flex: 1
  }
  footer p {
    flex: 1;
    text-align: right;
    padding-top: 40px;
    font-size: 20px;
  }
`;
