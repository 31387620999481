import 'firebase/firestore';

import * as Yup from 'yup';

import { Conteiner, MessageBox, MessageList } from './style';
import React, { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import Button from '../Button';
import { FiMessageCircle } from 'react-icons/fi';
import Image from './../Image';
import { Picker } from 'emoji-mart';
import api from "../../services/api";
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import getValidationErrors from '../../utils/getValidationErrors';
import moment from 'moment';
import profilePicture from '../../assets/images/profile-picture.png';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

interface ChatProps {
  user: any;
  channelId: string;
  params: any;
  vimeoChat: string;
};

interface IChat {
  id: string,
  channelId: string,
  eventId: string,
  createdAt: number,
  messages: IChatMessage[]
};

interface IChatMessage {
  uid: string,
  userName: string,
  userId: string,
  content: string,
  createdAt: number
};

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface ChatData {
  message: string;
}

const Chat: React.FC<ChatProps> = (props) => {
  const { t } = useTranslation();
  const { register, setValue, handleSubmit, errors, getValues } = useForm<ChatData>();
  const firestore = firebase.firestore();
  const [lastMessage, setLastMessage] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [$chat, setChat$] = useState<IChat>({} as IChat);
  const [message, setMessage] = useState("");
  const [showEmojis, setShowEmojis] = useState(false);

  useEffect(() => {
    let unsubscribe: any = null;

    getUserChat().then(async (source) => {
      unsubscribe = source.docSnap.onSnapshot((snapshot) => {
        let data = snapshot.data();
        if (data) {
          let c: any = { id: snapshot.id, ...data };
          setChat$(c);
          scrollBottom();
        }
      });

      setChat$(source.chat);

      scrollBottom();
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    }
  }, [props.channelId]);

  /// TESTS
  // useEffect(() => {
  //   if ($chat?.id) {
  //     let total = 0;

  //     setInterval(() => {
  //       const { id, name, email } = props.user;

  //       const data = {
  //         userId: id,
  //         userName: name || email,
  //         content: "Test " + (++total),
  //         createdAt: moment.utc().valueOf()
  //       };

  //       const ref = firestore.collection('chat').doc($chat?.id);
  //       setValue("message", "");

  //       if ($chat?.messages?.length <= 2000) {
  //         return ref.update({
  //           count: firebase.firestore.FieldValue.increment(1),
  //           messages: firebase.firestore.FieldValue.arrayUnion(data)
  //         });
  //       }
  //       else {
  //         setCounter(30);
  //         setLastMessage(moment.utc().add(30, 'seconds').valueOf());

  //         setTimeout(() => {
  //           return ref.update({
  //             count: firebase.firestore.FieldValue.increment(1),
  //             messages: firebase.firestore.FieldValue.arrayUnion(data)
  //           });
  //         }, 4000);
  //       }

  //     }, 500);
  //   }
  // }, [$chat?.id])

  async function getUserChat() {

    const docSnap = firestore.collection('chat').doc(props.channelId);
    let chat: any = null;
    let doc = await docSnap.get();
    // if (!doc.docs.length) {
    //   const data = {
    //     channelId: props.channelId,
    //     createdAt: moment.utc().valueOf(),
    //     count: 0,
    //     messages: []
    //   };

    //   await firestore.collection('chat').add(data);
    //   doc = await docSnap.get();
    // }
    const chatData = doc.data();
    if (chatData) {
      chat = chatData;
    }
    else {
      let result = await api.get(`/chat?channelId=${props.channelId}`);
      chat = result.data;
    }

    return {
      docSnap: firestore.collection('chat').doc(chat.id),
      chat
    };
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (counter >= 1) {
        setCounter(counter - 1);
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  })

  async function sendFirestoreMessage(content: string) {

    if (lastMessage > moment.utc().valueOf()) {
      return;
    }

    setCounter(20);
    setLastMessage(moment.utc().add(20, 'seconds').valueOf());

    const { id, name, email } = props.user;

    const data = {
      userId: id,
      userName: name || email,
      content,
      createdAt: moment.utc().valueOf()
    };

    const ref = firestore.collection('chat').doc($chat.id);
    setValue("message", "");

    return ref.update({
      count: firebase.firestore.FieldValue.increment(1),
      messages: firebase.firestore.FieldValue.arrayUnion(data)
    });
  }

  async function sendFunctionMessage(content: string) {
    const { id, name } = props.user;

    const data = {
      userId: id,
      userName: name,
      content,
      createdAt: moment.utc().valueOf()
    };

    const ref = firestore.collection('chat').doc($chat.id);
    setValue("message", "");
    return ref.update({
      count: firebase.firestore.FieldValue.increment(1),
      messages: firebase.firestore.FieldValue.arrayUnion(data)
    });
  }

  async function handleSendMessage(data: any) {
    try {
      const schema = Yup.object().shape({
        message: Yup.string().min(1, 'No mínimo 1 digitos').max(200, 'No maximo 200 digitos'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setShowEmojis(false);
      setMessage("");
      sendFirestoreMessage(data.message);

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        return;
      }
    }
  };

  function scrollBottom() {
    var element = document.getElementById("messageList");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  return (
    <Conteiner id="chat" className="tab-pane fade in active">
      {!!props.vimeoChat && <iframe className="chatEmbed" src={props.vimeoChat} style={{ width: '100%', padding: 5, overflow: 'hidden' }} height="470" frameBorder={0}></iframe>}
      {showEmojis && <Picker onSelect={(emoji: any) => { console.log(emoji); setMessage(message + (emoji as any).native) }} i18n={{
        search: 'Busca',
        notfound: 'Não Encontrado',
        categories: {
          search: 'Busca',
          recent: 'Mais Usados',
          people: 'Pessoas',
          nature: 'Natureza',
          foods: 'Comida',
          activity: 'Atividades',
          places: 'Lugares',
          objects: 'Objetos',
          symbols: 'Símbolos',
          flags: 'Bandeiras',
          custom: 'Customizados'
        }
      }} style={{ width: '100%' }} theme='light' showPreview={false} enableFrequentEmojiSort={false} useButton={false} showSkinTones={false} />}
      {!showEmojis && <MessageList id="messageList">
        {
          asEnumerable($chat?.messages || []).Where((m: any) => m.createdAt > moment.utc().subtract(6, 'hours')).Reverse().Take(100).Reverse().Select((message: any, i?: number) => {
            return <p key={i || 0}>
              <Image className="profile-picture" src={`https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2F${props.params.event}%2Fprofile%2Fthumbs%2F${message.userId}_35x35.png?alt=media`} default={profilePicture} />
              <span>{message.userName.indexOf('@') > -1 ? message.userName.split('@')[0] : message.userName}</span>: <strong>{message.content}</strong> <i>{moment(message.createdAt).format('HH:mm')}</i>
            </p>
          }).ToArray()
        }
      </MessageList>}
      <MessageBox>
        <form onSubmit={handleSubmit(handleSendMessage)}>
          <p>{props.user?.name || props.user?.email?.split('@')[0]} {counter > 0 && <>({counter})</>}</p>

          <div style={{ display: 'flex' }}>
            <a className="emojiButton" onClick={() => { setShowEmojis(!showEmojis) }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="black" d="M9.153 11.603c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962zm-3.204 1.362c-.026-.307-.131 5.218 6.063 5.551 6.066-.25 6.066-5.551 6.066-5.551-6.078 1.416-12.129 0-12.129 0zm11.363 1.108s-.669 1.959-5.051 1.959c-3.505 0-5.388-1.164-5.607-1.959 0 0 5.912 1.055 10.658 0zM11.804 1.011C5.609 1.011.978 6.033.978 12.228s4.826 10.761 11.021 10.761S23.02 18.423 23.02 12.228c.001-6.195-5.021-11.217-11.216-11.217zM12 21.354c-5.273 0-9.381-3.886-9.381-9.159s3.942-9.548 9.215-9.548 9.548 4.275 9.548 9.548c-.001 5.272-4.109 9.159-9.382 9.159zm3.108-9.751c.795 0 1.439-.879 1.439-1.962s-.644-1.962-1.439-1.962-1.439.879-1.439 1.962.644 1.962 1.439 1.962z"></path></svg>
            </a>
            <input onSelect={() => { setShowEmojis(false); }} type="text" maxLength={100} value={message} placeholder={t('Type here your message')} onChange={(e) => { setMessage(e.target.value) }} name="message" ref={register({ required: true, minLength: 1, maxLength: 100, })} />
          </div>
          {errors.message && <span>{t('Max 200 characteres')}</span>}
          <p>
            {message.length || 0}/100 <button type="submit"><i className="fas fa-paper-plane"></i></button>
          </p>
        </form>
      </MessageBox>
    </Conteiner>
  );
};

export default Chat;
