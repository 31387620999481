import styled, { css } from 'styled-components';

import { shade } from 'polished';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
  padding-top: 50px;
  min-height: 800px;

  .table-responsive {
    background: #002C4E;
    opacity: 0.75;
  }

  .box-header {
      text-align: left;
      ${(props) => css`
      background-color: ${props.customization.navbarBackground || 'rgba(190, 190, 190, 0.84)'};
      `}
      margin: 2px;
      padding: 20px;
      display: block;
      color: white;
      cursor: pointer;
    }

    .box-header:hover {
      color: wheat;
      text-decoration: none;
    }

    .box-header h4 {
      text-transform: uppercase;
    }

    .box-header p {
      font-size: 15px;
    }

    .box-content {
      margin: 2px;
      padding: 10px;
      margin-bottom: 10px;
      padding: 30px;
    }
`;
