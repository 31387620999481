import Channels from '../components/Channels';
import Dashboard from '../pages/Dashboard';
import Default from '../pages/Default';
import Feed from '../pages/Feed';
import Files from '../pages/Files';
import ForgotPassword from '../pages/ForgotPassword';
import Help from '../pages/Help';
import Home from '../pages/Home';
import LogOut from '../pages/LogOut';
import Profile from '../pages/Profile';
import React from 'react';
import ResetPassword from '../pages/ResetPassword';
import Route from './Route';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Success from '../pages/SignUp/Success';
import { Switch } from 'react-router-dom';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Default} />
    <Route path="/:event" exact component={Home} />
    <Route path="/:event/home" exact component={Home} />
    <Route path="/:event/signin" component={SignIn} />
    <Route path="/:event/signup/success" component={Success} />
    <Route path="/:event/signup" component={SignUp} />
    <Route path="/:event/forgot-password" component={ForgotPassword} />
    <Route path="/:event/reset-password" component={ResetPassword} />
    <Route path="/:event/dashboard/:channel" component={Dashboard} isPrivate />
    <Route path="/:event/dashboard" component={Dashboard} isPrivate />
    <Route path="/:event/files" component={Files} isPrivate />
    <Route path="/:event/help" component={Help} isPrivate />
    <Route path="/:event/feed" component={Feed} isPrivate />
    <Route path="/:event/logout" isPrivate component={LogOut} />
    <Route path="/:event/profile" component={Profile} isPrivate />
    <Route path="/:event/channels" exact component={Channels} />
  </Switch>
);

export default Routes;
