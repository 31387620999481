import * as Yup from 'yup';

import { FiLogIn, FiMail } from 'react-icons/fi';
import React, { useCallback, useRef, useState } from 'react';

import { AnimationContainer } from './styles';
import Button from '../../components/Button';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Input from '../../components/Input';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import getValidationErrors from '../../utils/getValidationErrors';
import { useEvent } from '../../hooks/EventContext';
import { useToast } from '../../hooks/Toast';
import { useTranslation } from 'react-i18next';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = (props: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const eventContext = useEvent();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required(t('Email is required'))
            .email(t('Type a valid email')),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/participant/forgot-password', {
          email: data.email,
          eventKey: props.params.event,
          lang: localStorage.getItem('defaultLanguage')
        });

        addToast({
          type: 'success',
          title: t('Recovery Password has been sent'),
          description: t('We sent an email to confirm password recovery, check your e-mail inbox'),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: t('Error'),
          description: t('An error occurred while trying to recover the password, please try again.'),
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <DefaultMasterPage {...props}>
      <AnimationContainer>
        <Form className="forgot-password-form" ref={formRef} onSubmit={handleSubmit}>
			 
			 <div className="pre-forgot-password-h1-recovery-password"></div>
          <h1 className="forgot-password-h1-recovery-password">{t('Recovery Password')}</h1>

          <Input name="email" icon={FiMail} placeholder="E-mail" />

			 <div className="pre-button-forgot-password-recovery"></div>
          <Button className="button-forgot-password-recovery" loading={loading} type="submit">
            {t('Recovery')}
          </Button>
		    
			 <div className="pre-a-forgot-password-back-to-home"></div>
          <Link className="link a-forgot-password-back-to-home" to="signin">
            {t('Back to login page')}
          </Link>
        </Form>


      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default ForgotPassword;
