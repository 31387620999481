import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import * as Yup from 'yup';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { useForm } from "react-hook-form";
import api from "../../../services/api";
import { Link, useHistory } from 'react-router-dom';
import { asEnumerable } from 'linq-es2015';

interface Props {
  user: any;
  channelId: string
  eventId: string
};

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface Files {
  channelId: string;
  eventId: string;
  index: number;
  responseIndex: number;
  question: string;
  active: boolean;
  options: { id: string, text: string, index: number }[];
  user: {
    email: string,
    id: string
  };
}

const Survey: React.FC<Props> = (props: any) => {
  const { register, setValue, handleSubmit, errors, getValues, reset } = useForm<Files>();
  const firestore = firebase.firestore();
  const [surveys, setSurveys] = useState<any[]>([]);
  const [formOptions, setFormOptions] = useState<any[]>([]);
  const [survey, setSurvey] = useState<any>();
  const { addToast } = useToast();

  useEffect(() => {
    if (props.channelId) {
      let unsubscribe: any = null;
      unsubscribe = firestore.collection('survey_question').where('channelId', '==', props.channelId).onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((a) => {
          return { id: a.id, ...a.data() };
        });

        const data = asEnumerable(docs).OrderByDescending((o: any) => o.createdAt).Select((survey: any, i) => {
          let count = 0;
          const total = asEnumerable(survey.options).Sum((d: any) => survey['count-' + (count++)] || 0);
          for (let index = 0; index < survey.options.length; index++) {
            const responseCount = survey['count-' + index] || 0;
            survey['percent-' + index] = (responseCount / total) * 100;
          }
          return survey;
        }).ToArray();

        setSurveys(data);

      });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
    }
  }, [props.channelId]);

  async function editSurvey(survey: any) {

    for (const key in survey) {
      if (survey.hasOwnProperty(key)) {
        const data = survey[key];

        if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
          setValue(key, data);
        }
      }
    }
    setSurvey(survey);
    setFormOptions(survey.options);
  }

  async function cancel() {
    reset();
    setSurvey(null);
    setFormOptions([]);
  }

  function deleteSurvey(id: string) {
    if (window.confirm("Tem certeza que deseja deletar esta enquete?")) {
      const ref = firestore.collection('survey_question').doc(id);
      ref.delete();
    }
  }

  function enableDisableSurvey(data: any) {
    const ref = firestore.collection('survey_question').doc(data.id);
    ref.update({
      active: !data.active
    });
  }

  function showResponses(data: any) {
    const ref = firestore.collection('survey_question').doc(data.id);
    ref.update({
      showResponses: !data.showResponses
    });
  }

  function addOption() {
    setFormOptions([...formOptions, {}]);
  }

  function removeOption(ind: number) {
    const arrOptions = [...formOptions];
    arrOptions.splice(ind, 1);
    setFormOptions(arrOptions);
  }

  const onSurveySubmit = handleSubmit(async (data: any) => {
    try {
      const { id, email } = props.user;

      if (!survey?.id) {
        data.user = {
          id,
          email
        };
        data.createdAt = moment.utc().valueOf();
        data.channelId = props.channelId;
        data.eventId = props.eventId;
      }

      data.options = [];

      let i = 0;
      while (data['opt-' + i]) {
        data.options.push({
          id: i + 1,
          text: data['opt-' + i],
          index: i
        })
        delete data['opt-' + i];
        i++;
      }
      if (survey?.id) {
        const ref = firestore.collection('survey_question').doc(survey?.id);
        ref.update(data);
      }
      else {
        await firestore.collection('survey_question').add(data);
      }

      addToast({
        type: 'success',
        title: 'Pesquisa salva com sucesso',
        description: 'Você já pode ativar a sua pesquisa',
      });

      reset();
      setFormOptions([]);
      setSurvey(null);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao salvar a pesquisa, tente novamente.',
      });
    }
  });

  return (
    <Conteiner>
      <form onSubmit={onSurveySubmit}>
        <div className="form-group">
          <label>Questão da Enquete</label>
          <input className="form-control" name="question" type="text" ref={register({ required: true, maxLength: 200 })} placeholder="Pergunta" />
          <>{errors.question && <span className="error">A pergunta da enquete é obrigatória</span>}</>
        </div>
        <div className="form-check">
          <input name="active" type="checkbox" className="form-check-input" ref={register} />
          <label className="form-check-label">&nbsp; Ativa</label>
        </div>
        <div className="form-group">
          <label>Opções </label>
          <table className="table">
            <tbody>
              {formOptions?.map((o: any, ind: number) => (
                <tr key={ind}>
                  <th scope="row">
                    {ind + 1}
                  </th>
                  <td>
                    <input className="form-control" defaultValue={o.text} type="text" name={`opt-${ind}`} placeholder="Opção" ref={register({ required: true })} />
                  </td>
                  <td>
                    <button onClick={() => { removeOption(ind) }} type="button" className="btn btn-primary">Remover</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={addOption} type="button" className="btn btn-primary">Add Opção</button>
        </div>
        <button type="submit" className="btn btn-primary">Salvar Enquete</button> &nbsp;&nbsp;
        <button onClick={cancel} type="button" className="btn btn-primary">Cancelar</button>
      </form>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Pergunta</th>
            <th scope="col">Ativa</th>
            <th scope="col">Respostas</th>
            <th style={{ width: '50%' }} scope="col">Opções</th>
            <th scope="col">Edição</th>
          </tr>
        </thead>
        <tbody>
          {surveys.map((s: any, index1: number) => (
            <tr key={index1}>
              <th scope="row">{surveys.length - index1}</th>
              <td>{s.question}</td>
              <td>{s.active ? 'Ativa' : 'Inativa'}</td>
              <td>{s.showResponses ? 'Exibindo' : 'Ocultas'}</td>
              <td>
                <table className="table">
                  <tbody>
                    {s?.options?.map((o: any, index2: number) => (
                      <tr key={index2}>
                        <th scope="row">{o.id}</th>
                        <td>{o.text}</td>
                        <td>{s['count-' + index2]}</td>
                        <td>{(s['percent-' + index2] || 0).toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Atualizar
                      </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={() => editSurvey(s)}>Editar</a>
                    <a className="dropdown-item" onClick={() => deleteSurvey(s.id)}>Deletar</a>
                    <a className="dropdown-item" onClick={() => enableDisableSurvey(s)}>{!s.active ? 'Ativar' : 'Desativar'} Enquete</a>
                    <a className="dropdown-item" onClick={() => showResponses(s)}>{!s.showResponses ? 'Mostrar' : 'Ocultar'} Respostas</a>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Conteiner>
  );
};

export default Survey;
