import "firebase/auth";
import "firebase/firestore"

import * as firebase from "firebase/app";

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Util } from '../utils/util';
import api from '../services/api';
import { useToast } from '../hooks/Toast';

interface User {
  id: string;
  role: string;
  name: string;
  email: string;
  avatar_url: string;
  fields: any;
}

interface SignInCredentials {
  email: string;
  password: string;
  eventKey: string;
  term: boolean;
  share: boolean;
}

interface AuthContextData {
  getUser(): User;
  integration(credentials: { token: string, eventKey: string }): Promise<void>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: User): void;
}

const Auth = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const [skipSignOut, setSkipSignOut] = useState<boolean>(false);

  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged(async (firebaseUser: firebase.User | null) => {
  //     if (firebaseUser) {
  //       if (!user || user.refreshToken != firebaseUser.refreshToken) {

  //         setUser(firebaseUser);
  //         await updateToken(firebaseUser);
  //         if (location.pathname.indexOf("/dashboard") == -1) {
  //           history.replace("dashboard");
  //         }
  //       }
  //     }
  //   });

  //   return () => {
  //     unsubscribe();
  //   }
  // }, [user]);

  const [user, setUser] = useState<any>(() => {
    const sUser = localStorage.getItem('@Storage:user');
    if (sUser) {
      return JSON.parse(sUser);
    }
    return null;
  });

  const [token, setToken] = useState<string>(() => {
    const sToken = localStorage.getItem('@Storage:token');

    if (sToken) {
      api.defaults.headers.authorization = `Bearer ${sToken}`;
      return sToken;
    }
    return '';
  });

  const updateToken = (refreshToken: string) => {
    api.defaults.headers.authorization = `Bearer ${refreshToken}`;
    localStorage.setItem('@Storage:token', refreshToken);
    setToken(refreshToken);
    return token;
  };

  async function updateUser(user: any) {
    const userConverted: User = user?.displayName ? {
      avatar_url: user?.photoURL || "",
      name: user?.displayName || "",
      email: user?.email || "",
      id: user?.uid || "",
      fields: user?.fields
    } : user

    //const profile = await api.get("/profile");
    localStorage.setItem('@Storage:user', JSON.stringify(userConverted));

    setUser(user);
    return userConverted;
  }

  const getUser = () => {
    return user;
  }

  const integration = useCallback(async ({ token, eventKey }) => {
    let result = null;

    addToast({
      type: 'success',
      title: 'Aguarde...',
      description: 'Estamos iniciando sua conexão',
      seconds: 15000
    });

    try {
      result = await api.post("/sessions/integration", {
        token,
        eventKey: eventKey?.toLowerCase()
      });
    } catch (error) {
      try {
        await Util.sleep(2);
        result = await api.post("/sessions/integration", {
          token,
          eventKey: eventKey?.toLowerCase()
        });
      } catch (error) {
        try {
          await Util.sleep(2);
          result = await api.post("/sessions/integration", {
            token,
            eventKey: eventKey?.toLowerCase()
          });
        } catch (error) {
          addToast({
            type: 'error',
            title: 'Acesso Negado',
            description: 'Solicite acesso para o seu administrador',
          });
          throw error;
        }
      }
    }

    localStorage.setItem('@Storage:usertoken', result.data.token);

    const signedUser = await firebase.auth().signInWithCustomToken(result.data.token);
    updateToken(await signedUser?.user?.getIdToken(true) || "");
    const user = updateUser(result.data.user);

    setSkipSignOut(true);
    setTimeout(() => {
      document.location.href = `/${eventKey?.toLowerCase()}/`;
    }, 500);
  }, []);

  const signIn = useCallback(async ({ email, password, eventKey, term, share }) => {
    let result: any = null;
    // try 3x
    try {
      result = await api.post("/sessions/login", {
        email,
        password,
        eventKey: eventKey?.toLowerCase(),
        term,
        share
      });
    } catch (error) {
      try {
        await Util.sleep(2);
        result = await api.post("/sessions/login", {
          email,
          password,
          eventKey: eventKey?.toLowerCase(),
          term,
          share
        });
      } catch (error) {
        try {
          await Util.sleep(2);
          result = await api.post("/sessions/login", {
            email,
            password,
            eventKey: eventKey?.toLowerCase(),
            term,
            share
          });
        } catch (error) {
          throw error;
        }
      }
    }

    localStorage.setItem('@Storage:usertoken', result.data.token);

    const signedUser = await firebase.auth().signInWithCustomToken(result.data.token);
    updateToken(await signedUser?.user?.getIdToken(true) || "");
    const user = updateUser(result.data.user);
    setSkipSignOut(true);
    setTimeout(() => {
      if (eventKey?.toLowerCase() == 'perkin-saleskickoffmeeting') {
        document.location.href = `/perkin-saleskickoffmeeting/`;
      }
      else {
        document.location.href = `/${eventKey?.toLowerCase()}/`;
      }
    }, 500);
  }, []);

  const signOut = useCallback(async () => {
    const token = localStorage.getItem('@Storage:token');

    if (!!token) {
      try {
        api.post("/sessions/logout").then(() => {
          setToken("");
          setUser(null);
          localStorage.removeItem('@Storage:token');
          localStorage.removeItem('@Storage:user');
          localStorage.removeItem('@Storage:usertoken');
        }).catch(() => {
          api.post("/sessions/logout").then(() => {
            setToken("");
            setUser(null);
            localStorage.removeItem('@Storage:token');
            localStorage.removeItem('@Storage:user');
            localStorage.removeItem('@Storage:usertoken');
          }).catch(() => {
            setToken("");
            setUser(null);
            localStorage.removeItem('@Storage:token');
            localStorage.removeItem('@Storage:user');
            localStorage.removeItem('@Storage:usertoken');
          });;
        });
      } catch (error) {
        setToken("");
        setUser(null);
        localStorage.removeItem('@Storage:token');
        localStorage.removeItem('@Storage:user');
        localStorage.removeItem('@Storage:usertoken');
      }
    }
  }, []);

  // const updateUser = useCallback(
  //   (user: User) => {
  //     localStorage.setItem('@Storage:user', JSON.stringify(user));

  //     let currentUser = firebase.auth().currentUser;
  //     if (currentUser) {
  //       let updatedUser = { ...currentUser, ...user };
  //       firebase.auth().updateCurrentUser(updatedUser);
  //     }

  //     setData({
  //       token: data.token,
  //       user
  //     });
  //   },
  //   [setData, data.token],
  // );

  document.onkeydown = fkey;
  document.onkeypress = fkey
  document.onkeyup = fkey;

  let wasPressed = false;

  function fkey(e: any) {
    e = e || window.event;
    if (wasPressed) return;

    if (e.keyCode == 116) {
      wasPressed = true;
    }
  }

  window.onbeforeunload = function (e: any) {
    const ev = e;
    if (window?.document?.activeElement?.localName == 'body' && !wasPressed && !skipSignOut && user?.id) {
      //signOut();
    }
  }

  async function keepAlive() {
    try {
      const token = localStorage.getItem('@Storage:token');
      if (token) {
        const result = await api.post("/sessions/keep-alive");
        const usertoken = result.data;
        if (usertoken) {

          const signedUser = await firebase.auth().signInWithCustomToken(usertoken);
          const token = await signedUser?.user?.getIdToken(true);
          if (token) {
            updateToken(token);
          }
        }
      }
    } catch (error) {
      console.log('Cannot keep connection alive');
    }
  }

  useEffect(() => {
    const unsubscribe = setInterval(keepAlive, 60000 * 3);
    return () => {
      clearInterval(unsubscribe);
    };
  }, [user]);

  const firestore = firebase.firestore();
  useEffect(() => {
    const token = localStorage.getItem('@Storage:usertoken');
    if (token && user && user.eventId) {
      const unsubscribe = firestore.collection('logout').doc(user.id).onSnapshot((snapshot) => {
        const data = snapshot.data();
        data?.tokens.map((uid: any) => {
          if (uid == token) {
            signOut();
          }
        });
        //setTimeline(items);
      });
      return () => {
        unsubscribe();
      }
    }
  }, [user]);

  return (
    <Auth.Provider value={{ integration, getUser, signIn, signOut, updateUser }}>
      {children}
    </Auth.Provider >
  );
};

function useAuth(): AuthContextData {
  const context = useContext(Auth);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
