import * as Yup from 'yup';
import * as allIcons from 'react-icons/fi';

import { AnimationContainer, Background, Container, Content } from './styles';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Button from '../../components/Button';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import { Util } from '../../utils/util';
import api from '../../services/api';
import { asEnumerable } from 'linq-es2015';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useForm } from 'react-hook-form';
import { useToast } from '../../hooks/Toast';
import { useTranslation } from 'react-i18next';

const icons: any = allIcons;
let obs: string;
interface SignUpFormData {
  eventKey: string;
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const errorsAny: any = errors;
  const { addToast } = useToast();
  const history = useHistory();
  const eventContext = useEvent();
  const [passwordMessage, setPasswordMessage] = useState<any>();
  const { getUser } = useAuth();
  const user = getUser();
  const [fields, setFields] = useState<any>({});

  const [medic, setMedic] = React.useState(false);


  const handleSelect = (event: any) => {
    const { value } = event.target;
    setMedic(value === 'medico');
  };


  // if (user && user.id) {
  //   history.replace(`/${props.params.event}/dashboard`);
  // }

  // if (eventContext?.event?.key === '7-forum-abradilan') {
  //   obs = 'Obs: Digite apenas números no campo CNPJ';
  // }

  const emailList = [
    'aline.silva@orgafarma.com.br',
    'mariana.costa@orgafarma.com.br',
  ];

  const onSubmit = handleSubmit(async (data: any) => {

    const handleParticipantRegistration = async () => {
      data.eventKey = props.params.event?.toLowerCase();
      data.fields = data.fields || {};
      data.fields['lang'] = localStorage.getItem('defaultLanguage');
      delete data.password_confirmation;
  
      let participant: any;
      for (let i = 0; i < 3; i++) {
        try {
          participant = await api.post('/participant', data);
          break;
        } catch (error) {
          if (i === 2) throw error;
          await Util.sleep(4);
        }
      }
  
      if (eventContext?.event?.settings?.login?.registerSuccess) {
        history.push(`/${eventContext.eventKey?.toLowerCase()}/signup/success`);
      } else {
        addToast({
          type: 'success',
          title: 'Cadastro realizado',
          description: 'Você já pode fazer seu login!',
        });
        history.push(`/${eventContext.eventKey?.toLowerCase()}/signin`);
      }
    };
  
    const handleErrors = (error: any) => {
      setLoading(false);
  
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description:
          error?.response?.data?.message ||
          error.message ||
          'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    };
  
    const schema = Yup.object().shape({
      password: Yup.string().required(t('Password is required')),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        t('Invalid confirmation'),
      ),
    });
  
    try {
      if (eventContext?.event?.settings?.login?.type === 'number') {
        data.email = data.email.replace(/[\. ,:-]+/g, '');
      }
    } catch { }
  
    await schema.validate(data, {
      abortEarly: true,
    });
  
    setLoading(true);
  
    if (
      (eventContext?.event?.key === 'orgafarma2023' && emailList.includes(data['email'].toLowerCase()))
      || eventContext?.event?.key != 'orgafarma2023'
    ) {
      try {
        await handleParticipantRegistration();
      } catch (error) {
        handleErrors(error);
      }
    } else {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Email não permitido!',
      });
    }
  });
  

  const emailValidation = (email: string) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const passwordValidation = (password: string) => {
    let validation = <></>;

    // Validate lowercase letters
    var lowerCaseLetters = /[a-z]/g;
    if (!password.match(lowerCaseLetters)) {
      validation = (
        <React.Fragment>
          {' '}
          {validation}{' '}
          <span style={{ color: errorColor }} className="error">
            {t('Password must have one character lowercase')}
          </span>
          <br />
        </React.Fragment>
      );
    }

    // Validate capital letters
    var upperCaseLetters = /[A-Z]/g;
    if (!password.match(upperCaseLetters)) {
      validation = (
        <React.Fragment>
          {' '}
          {validation}{' '}
          <span style={{ color: errorColor }} className="error">
            {t('Password must have one character uppercase')}
          </span>
          <br />
        </React.Fragment>
      );
    }

    // Validate numbers
    var numbers = /[0-9]/g;
    if (!password.match(numbers)) {
      validation = (
        <React.Fragment>
          {' '}
          {validation}{' '}
          <span style={{ color: errorColor }} className="error">
            {t('The password must have at least one number')}
          </span>
          <br />
        </React.Fragment>
      );
    }

    // Validate length
    if (password.length < 8) {
      validation = (
        <React.Fragment>
          {' '}
          {validation}{' '}
          <span style={{ color: errorColor }} className="error">
            {t('Password must be at least 8 characters')}
          </span>
          <br />
        </React.Fragment>
      );
    }

    setPasswordMessage(validation);

    return validation !== <></>;
  };

  function onChangeSelect(field: any, obj: any) {
    if (field.key) {
      const newFields = { ...fields };
      const registerFields = asEnumerable(
        eventContext?.event?.registerFields || [],
      )
        .OrderBy((f: any) => f.order)
        .ToArray() as any[];

      for (let i = 0; i < registerFields.length; i++) {
        const item = registerFields[i];
        if (item.dependency) {
          const dependency = item.dependency.split(',');

          if (field.key == dependency[0]) {
            if (obj.currentTarget.value == dependency[1]) {
              newFields[i] = true;
            } else {
              newFields[i] = false;
            }
          }
        }
      }

      setFields(newFields);
    }
  }

  function getFieldName(field: any) {
    let strFieldName = !!field?.name_languages
      ? field.name_languages[i18n.language] || ''
      : field?.name || '';
    if (field.required) {
      return `${strFieldName}*`;
    }
    return strFieldName;
  }

  let errorColor = eventContext?.event?.customization?.register?.errorColor;
  errorColor = errorColor ? errorColor : '#ffa799';

  return (
    <DefaultMasterPage {...props}>
      <AnimationContainer>
        {/* BRF PIXEL */}
        {eventContext?.event?.key?.indexOf('brf') != -1 && <div></div>}

        <form className="signup-form" onSubmit={onSubmit}>
          <div className="pre-signup-register"></div>
          <h1 className="signup-register">
            {eventContext?.event?.settings?.texts?.signUpTitle || t('Register')}{' '}
          </h1>
          {obs}
          {eventContext && eventContext.event && eventContext.event.key === 'adderamax' ? (
            <div style={{ padding: '15px' }}>

              <label style={{ marginRight: '25px' }}>
                <input
                  type="radio"
                  name="userType"
                  value="colaborador"
                  checked={!medic}
                  onChange={handleSelect}
                />
                Colaborador Hypera
              </label>
              <label >
                <input
                  type="radio"
                  name="userType"
                  value="medico"
                  checked={medic}
                  onChange={handleSelect}
                />
                Médico
              </label>
            </div>
          ) : null}

          {asEnumerable(eventContext?.event?.registerFields || [])
            .OrderBy((f: any) => f.order)
            .Select((field: any, i: any) => {
              let formResult: JSX.Element = <></>;
              // formResult = field.type == 'text' && (!field.dependency || fields[i]) ? (<input type="text" key={i} name={`dyn${(!!field?.name_languages ? field.name_languages[i18n.language] || '' : field?.name || '').replace(/ /gi, '').toLowerCase()}`} placeholder={getFieldName(field)} ref={register({ required: field.required })} />) : formResult;
              formResult =
                (field.key == 'Especialidade' || field.key == 'CRM/Residente/Nutricionista' || field.key == 'Celular')
                  && (!field.dependency || fields[i])
                  && medic ? (
                  <input
                    type="text"
                    key={i}
                    name={`dyn-${field?.key?.replace(/ /gi, '').toLowerCase()}`}
                    placeholder={getFieldName(field)}
                    ref={register({ required: field.required })}
                  />
                ) : formResult;

              formResult =
                field.type == 'text'
                  && field.key != 'Especialidade'
                  && field.key != 'CRM/Residente/Nutricionista'
                  && field.key != 'Celular'
                  && (!field.dependency || fields[i]) ? (
                  <input
                    type="text"
                    key={i}
                    name={`dyn-${field?.key?.replace(/ /gi, '').toLowerCase()}`}
                    placeholder={getFieldName(field)}
                    ref={register({ required: field.required })}
                  />
                ) : formResult;


              formResult =
                field.type == 'date' && (!field.dependency || fields[i]) ? (
                  <input
                    type="date"
                    key={i}
                    name={`dyn-${(!!field?.name_languages
                      ? field.name_languages[i18n.language] || ''
                      : field?.name || ''
                    )
                      ?.replace(/ /gi, '')
                      .toLowerCase()}`}
                    placeholder={getFieldName(field)}
                    ref={register({ required: field.required })}
                  />
                ) : (
                  formResult
                );
              formResult =
                field.type == 'email' && (!field.dependency || fields[i]) ? (
                  <input
                    type="email"
                    key={i}
                    name={`dyn-${(!!field?.name_languages
                      ? field.name_languages[i18n.language] || ''
                      : field?.name || ''
                    )
                      ?.replace(/ /gi, '')
                      .toLowerCase()}`}
                    placeholder={getFieldName(field)}
                    ref={register({ required: field.required })}
                  />
                ) : (
                  formResult
                );
              formResult =
                field.type == 'number' && (!field.dependency || fields[i]) ? (
                  <input
                    type="number"
                    key={i}
                    name={`dyn-${(!!field?.name_languages
                      ? field.name_languages[i18n.language] || ''
                      : field?.name || ''
                    )
                      ?.replace(/ /gi, '')
                      .toLowerCase()}`}
                    placeholder={getFieldName(field)}
                    ref={register({ required: field.required })}
                  />
                ) : (
                  formResult
                );
              formResult =
                field.type == 'select' && (!field.dependency || fields[i]) ? (
                  <select
                    onChange={(event) => onChangeSelect(field, event)}
                    key={i}
                    name={`dyn-${(!!field?.name_languages
                      ? field.name_languages[i18n.language] || ''
                      : field?.name || ''
                    )
                      .replace(/ /gi, '')
                      .toLowerCase()}`}
                    ref={register({ required: field.required })}
                    placeholder={
                      !!field?.name_languages
                        ? field.name_languages[i18n.language] || ''
                        : field?.name || ''
                    }
                  >
                    <option value="">
                      {!!field?.name_languages
                        ? field.name_languages[i18n.language] || ''
                        : field?.name || ''}
                    </option>
                    {asEnumerable(field?.dataList || [])
                      .OrderBy((f: any) => f.order)
                      .Select((value: any, i) => (
                        <option key={i} value={value}>
                          {value}
                        </option>
                      ))}
                  </select>
                ) : (
                  formResult
                );
              return (
                <React.Fragment key={i}>
                  {formResult}{' '}
                  <>
                    {errorsAny[
                      `dyn-${(!!field?.name_languages
                        ? field.name_languages[i18n.language] || ''
                        : field?.name || ''
                      )
                        .replace(/ /gi, '')
                        .toLowerCase()}`
                    ] && (
                        <span style={{ color: errorColor }} className="error">
                          {!!field?.name_languages
                            ? field.name_languages[i18n.language] || ''
                            : field?.name || ''}{' '}
                          {t('is required')}
                        </span>
                      )}
                  </>
                </React.Fragment>
              );
            })
            .ToArray()}

          <input
            name="email"
            type="text"
            ref={register({ required: true })}
            placeholder={`${eventContext?.event?.settings?.texts?.signUpLogin || t('Email')
              }*`}
          />
          <>
            {errorsAny[`email`] && (
              <span style={{ color: errorColor }} className="error">
                {t(
                  `${eventContext?.event?.settings?.texts?.signUpLogin ||
                  t('Email')
                  } is required`,
                )}
              </span>
            )}
          </>

          <input
            name="password"
            type="password"
            ref={register({
              required: true,
              validate: eventContext.event?.settings?.hasPasswordSecurity
                ? passwordValidation
                : undefined,
            })}
            placeholder={`${t('Password')}*`}
          />
          <>
            {errorsAny[`password`] && (
              <span style={{ color: errorColor }} className="error">
                {t(`Password is required`)}
              </span>
            )}
          </>
          <br />
          <>{passwordMessage}</>
          <input
            name="password_confirmation"
            type="password"
            ref={register({ required: true })}
            placeholder={t('Password Confirmation')}
          />
          <>
            {errorsAny[`password_confirmation`] && (
              <span style={{ color: errorColor }} className="error">
                {t('Password confirmation is required')}
              </span>
            )}
          </>
          <div style={{ textAlign: 'center' }}>
            {asEnumerable(eventContext?.event?.terms || [])
              .OrderBy((f: any) => f.order)
              .Select((term: any, i) => (
                <div
                  key={i}
                  style={{
                    padding: '20px',
                    fontSize: '9px',
                    marginBottom: '0',
                  }}
                >
                  {term.description ||
                    t('Do you accept the terms of use of the platform?')}
                  <div>
                    <input
                      name={`term-${i}`}
                      type="checkbox"
                      ref={register({ required: true })}
                    />{' '}
                    {t('Yes')}{' '}
                    {!!term.termsFile && (
                      <>
                        (
                        <a
                          target="_blank"
                          style={{ display: 'inline' }}
                          href={term.termsFile}
                        >
                          {t('Click here to read the terms')}
                        </a>
                        )
                      </>
                    )}
                  </div>
                </div>
              ))
              .ToArray()}
          </div>

          <div className="pre-button-register"></div>
          <Button className="button-register" loading={loading} type="submit">
            {eventContext?.event?.settings?.texts?.signUpRegister ||
              t('Register')}
          </Button>

          <div className="pre-back-to-login"></div>
          <Link
            className="link back-to-login"
            to={`/${props.params.event}/signin`}
          >
            {t('Back to Sign In')}
          </Link>

          {!!eventContext.event?.customization?.signInImg && (
            <img
              style={{ paddingTop: 30 }}
              src={eventContext.event?.customization?.signInImg}
            ></img>
          )}
        </form>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default SignUp;
