import axios from 'axios';

const debug = 'http://localhost:3120';
const emulator = 'http://localhost:3333/antonicelli-official/us-central1/api';
const remote = 'https://us-central1-antonicelli-official.cloudfunctions.net/api';//'https://acevents-api-ynnmy.ondigitalocean.app'; 
let url = remote;

if (document.location.href.indexOf('localhost') > -1) {
  url = debug;
} else if (document.location.href.indexOf('localhost:3331') > -1) {
  url = emulator;
}

const api = axios.create({
  baseURL: url,
});

export default api;
