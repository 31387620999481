import 'firebase/firestore';

import * as Yup from 'yup';

import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Conteiner } from './style';
import api from "../../../services/api";
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { useToast } from '../../../hooks/Toast';

interface ChatProps {
  user: any;
  channelId: string
  eventId: string
};

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface RaffleItem {
  channelId: string;
  eventId: string;
  index: number;
  responseIndex: number;
  item: string;
  active: boolean;
  options: { id: string, text: string, index: number }[];
  user: {
    email: string,
    id: string
  };
}

const Raffle: React.FC<ChatProps> = (props: any) => {
  const { register, setValue, handleSubmit, errors, getValues, reset } = useForm<RaffleItem>();
  const firestore = firebase.firestore();
  const [raffles, setRaffles] = useState<any[]>([]);
  const [formOptions, setFormOptions] = useState<any[]>([]);
  const [raffle, setRaffle] = useState<any>();
  const { addToast } = useToast();

  useEffect(() => {
    if (props.channelId) {
      let unsubscribe: any = null;
      unsubscribe = firestore.collection('raffle').where('channelId', '==', props.channelId).onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((a) => {
          return { id: a.id, ...a.data() };
        });

        const data = asEnumerable(docs).OrderByDescending((o: any) => o.createdAt).Select((raffle: any, i) => {
          let count = 0;
          const total = asEnumerable(raffle.options).Sum((d: any) => raffle['count-' + (count++)] || 0);
          for (let index = 0; index < raffle.options.length; index++) {
            const responseCount = raffle['count-' + index] || 0;
            raffle['percent-' + index] = (responseCount / total) * 100;
          }
          return raffle;
        }).ToArray();

        setRaffles(data);

      });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
    }
  }, [props.channelId]);

  async function editRaffle(raffle: any) {

    for (const key in raffle) {
      if (raffle.hasOwnProperty(key)) {
        const data = raffle[key];

        if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
          setValue(key, data);
        }
      }
    }
    setRaffle(raffle);
    setFormOptions(raffle.options);
  }

  async function cancel() {
    reset();
    setRaffle(null);
    setFormOptions([]);
  }

  function deleteRaffle(id: string) {
    if (window.confirm("Tem certeza que deseja deletar esta item?")) {
      const ref = firestore.collection('raffle').doc(id);
      ref.delete();
    }
  }

  function enableDisableRaffle(data: any) {
    const ref = firestore.collection('raffle').doc(data.id);
    ref.update({
      active: !data.active
    });
  }

  function showResponses(data: any) {
    const ref = firestore.collection('raffle').doc(data.id);
    ref.update({
      showResponses: !data.showResponses
    });
  }

  function addOption() {
    setFormOptions([...formOptions, {}]);
  }

  function removeOption(ind: number) {
    const arrOptions = [...formOptions];
    arrOptions.splice(ind, 1);
    setFormOptions(arrOptions);
  }

  const onRaffleSubmit = handleSubmit(async (data: any) => {
    try {
      const { id, email } = props.user;

      if (!raffle?.id) {
        data.user = {
          id,
          email
        };
        data.createdAt = moment.utc().valueOf();
        data.channelId = props.channelId;
        data.eventId = props.eventId;
      }

      data.options = [];

      let i = 0;
      while (data['opt-' + i]) {
        data.options.push({
          id: i + 1,
          text: data['opt-' + i],
          index: i
        })
        delete data['opt-' + i];
        i++;
      }
      if (raffle?.id) {
        const ref = firestore.collection('raffle').doc(raffle?.id);
        ref.update(data);
      }
      else {
        await firestore.collection('raffle').add(data);
      }

      addToast({
        type: 'success',
        title: 'Pesquisa salva com sucesso',
        description: 'Você já pode ativar a sua pesquisa',
      });

      reset();
      setFormOptions([]);
      setRaffle(null);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao salvar a pesquisa, tente novamente.',
      });
    }
  });

  return (
    <Conteiner>
      <form onSubmit={onRaffleSubmit}>
        <div className="form-group">
          <label>Nome do sorteio</label>
          <input className="form-control" name="item" type="text" ref={register({ required: true, maxLength: 200 })} placeholder="Descrição" />
          <>{errors.item && <span className="error">A descrição do Item é obrigatório</span>}</>
        </div>
        <div className="form-check">
          <input name="active" type="checkbox" className="form-check-input" ref={register} />
          <label className="form-check-label">&nbsp; Ativa</label>
        </div>        
        <button type="submit" className="btn btn-primary">Salvar Sorteio</button> &nbsp;&nbsp;
        <button onClick={cancel} type="button" className="btn btn-primary">Cancelar</button>
      </form>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome</th>
            <th scope="col">Ativa</th>
            <th style={{ width: '50%' }} scope="col">Opções</th>
            <th scope="col">Edição</th>
          </tr>
        </thead>
        <tbody>
          {raffles.map((s: any, index1: number) => (
            <tr key={index1}>
              <th scope="row">{raffles.length - index1}</th>
              <td>{s.item}</td>
              <td>{s.active ? 'Ativa' : 'Inativa'}</td>
              <td>{s.showResponses ? 'Exibindo' : 'Ocultas'}</td>
              <td>
                <table className="table">
                  <tbody>
                    {s?.options?.map((o: any, index2: number) => (
                      <tr key={index2}>
                        <th scope="row">{o.id}</th>
                        <td>{o.text}</td>
                        <td>{s['count-' + index2]}</td>
                        <td>{(s['percent-' + index2] || 0).toFixed(2)}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Atualizar
                      </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={() => editRaffle(s)}>Editar</a>
                    {/* <a className="dropdown-item" onClick={() => deleteRaffle(s.id)}>Deletar</a> */}
                    <a className="dropdown-item" onClick={() => enableDisableRaffle(s)}>{!s.active ? 'Ativar' : 'Desativar'} Sorteio</a>
                    <a className="dropdown-item" onClick={() => enableDisableRaffle(s)}>{!s.active ? 'Iniciar ' : 'Desativar'} Sorteio</a>
                    <a className="dropdown-item" onClick={() => showResponses(s)}>{!s.showResponses ? 'Mostrar' : 'Ocultar'} Respostas</a>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Conteiner>
  );
};

export default Raffle;
