import styled, { css } from 'styled-components';
import { shade } from 'polished';


interface ContainerPros {
  customization: any;
}

export const Conteiner = styled.div<ContainerPros>`

.overlay p {
  font-size: 14px;
  color: white;
  margin-top: 2px;
  margin-right: 7px;
  margin-bottom: 2px;
}

.overlay {
  position: absolute;
  width: 100%;
  
  // background: rgba(0, 0, 0, 0.7);
  max-width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  text-align: right;
  z-index: 999;
}


/* info END */
.room-header {
  ${(props) => css`
  background-color: ${props.customization.navbarBackground || 'rgba(208, 0, 112, 0.4)'};
  color: ${props.customization.fontColor || 'rgba(208, 0, 112, 0.4)'};
  `}
  text-align: center;
  padding: 1px 0;
  margin-bottom: 30px;
  margin-top: 15px;
  opacity: 0.8;
}

#room-container h4 {
  font-size: 20px;
  font-weight: normal;
}

.rooms .col-md-3 {
  margin-bottom: 40px;
  padding-left: 0;
}

.roomBox {
  padding-right: 0px;
  margin-bottom: 15px;
}

.room {
  position: relative;
  z-index: 2;
  min-height: 200px;

  .bgImage {
    height: 100px;
    min-width: 100%;
    background-size: cover;
  }
}

/* HERE */
.room .title {
  text-align: right;
  position: absolute;
  left: 30px;
  top: 15px;
  z-index: 1;
  ${(props) => css`
  color: ${props.customization.fontColor || 'white'};
  `};
  font-size: 25px;
  font-weight: bold;
}

.room > .texto {
  ${(props) => css`
  background-color: ${props.customization.navbarBackground || 'black'};
  `};
  text-align: left;
  padding: 15px;
  height: 250px;
  overflow-y: scroll;
  ${(props) => css`
  color: ${props.customization.fontColor || 'white'};
  `};
}

h2 {
  color: black !important;
  font-size: 130%;
}

/* width */
.room > .texto::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.room > .texto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
}

/* Handle */
.room > .texto::-webkit-scrollbar-thumb {
  ${(props) => css`
  background: ${props.customization.buttonBackgroundColor || '#f1f1f1'};
  `};
}

/* Handle on hover */
.room > .texto::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`;
