import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.button`
  background: #2463B2;
  height: 56px;
  border-radius: 3px;
  border: 0;
  padding: 0 5px;
  color: #fff;
  width: 100%;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s;
`;
