import 'firebase/firestore';

import * as Yup from 'yup';

import React, { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import Button from '../Button';
import { Conteiner } from './style';
import { FiMessageCircle } from 'react-icons/fi';
import api from "../../services/api";
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import getValidationErrors from '../../utils/getValidationErrors';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { useToast } from '../../hooks/Toast';
import { useTranslation } from 'react-i18next';
import { useEvent } from '../../hooks/EventContext';

interface QuestionProps {
  user: any;
  channelId: string;
  eventId: string;
  customization: any;
};

interface IQuestion {
  id: string;
  channelId: string;
  eventId: string;
  speakerId: string;
  question: string;
  response: string;
  userId: string;
  name: string;
  email: string;
  createdAt: number;
}

interface IQuestionData {
  question: string;
}

const Question: React.FC<QuestionProps> = (props) => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { register, unregister, setValue, handleSubmit, errors, getValues } = useForm<IQuestionData>();
  const firestore = firebase.firestore();

  async function sendMessage(question: string) {
    const data = {
      channelId: props.channelId,
      eventId: props.eventId,
      question,
      userId: props.user.id,
      email: props.user.email,
      name: props.user.name || props.user.email.split("@")[0].toUpperCase(),
      createdAt: moment.utc().valueOf()
    } as IQuestion;

    const ref = await firestore.collection('question').add(data);

    addToast({
      type: 'success',
      title: t('Message has been sent'),
      description: t('Thank you for your participation'),
    });
  }

  async function handleSendMessage(data: any) {
    try {
      const schema = Yup.object().shape({
        question: Yup.string().required(t('The message is required'))
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await sendMessage(data.question);

      setValue('question', '');

    } catch (error) {
      addToast({
        type: 'error',
        title: t('Error to send message'),
        description: error?.response?.data?.message || error.message,
      });
    }
  };

  const eventContext = useEvent();

  return (
    <Conteiner id="question" customization={props.customization} className={`chatbox tab-pane fade ${eventContext.channel?.resources?.length == 1 ? 'in active' : ''}`}>
      <h3>{t('Send a question to speakers')}</h3>
      <form onSubmit={handleSubmit(handleSendMessage)}>
        <textarea style={{border: '1px solid black'}} name="question" ref={register({ required: true, maxLength: 1000 })} cols={30} rows={4}></textarea>
        <button type="submit" className="btn btn-primary pull-right">{t('Send')}</button>
        <React.Fragment>{errors.question && <span className="error" style={{ float: 'right' }}>{t('The question is required')}</span>}</React.Fragment>

      </form>
    </Conteiner>
  );
};

export default Question;
