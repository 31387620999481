import React from 'react';
import { Container } from './styles';
import logoImage from '../../assets/images/logo.png'

export interface MasterProps {
  noContent: boolean;
}

const OpenMasterPage: React.FC<MasterProps> = (props) => {

  return (
    <Container>
      <main>
        <article className={props.noContent ? "screen col-md-12" : "screen col-md-8"}>
          <div className="content">
            <img src={logoImage} alt="LogoAC" />
            <h1>Plataforma Digital <br /> Para Eventos On-Line</h1>
          </div>
        </article>
        {!props.noContent ? (<aside className="col-md-4">{props.children}</aside>) : ''}
      </main>
    </Container>
  );
};

export default OpenMasterPage;
