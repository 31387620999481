import 'emoji-mart/css/emoji-mart.css';
import 'antd/dist/antd.css';

import styled, { createGlobalStyle, css } from 'styled-components';

import { shade } from 'polished';

//import 'bootstrap/dist/css/bootstrap.min.css';
interface ContainerPros {
  customization: any;
}
const fontChangelingEot = require('../assets/fonts/changeling/2d5f91cbc8faa21103d08ce9d19040d6.eot');
const fontChangelingWoff2 = require('../assets/fonts/changeling/2d5f91cbc8faa21103d08ce9d19040d6.woff2');
const fontChangelingWoff = require('../assets/fonts/changeling/2d5f91cbc8faa21103d08ce9d19040d6.woff');
const fontChangelingTruetype = require('../assets/fonts/changeling/2d5f91cbc8faa21103d08ce9d19040d6.ttf');
const fontChangelingSvg = require('../assets/fonts/changeling/2d5f91cbc8faa21103d08ce9d19040d6.svg');

export default createGlobalStyle<ContainerPros>`
@font-face {
  font-family: "Changeling Neo W01 Bold";
  src: url("${fontChangelingEot}");
  /* IE9*/
  src: url("${fontChangelingEot}") format("embedded-opentype"),
    /* IE6-IE8 */
    url("${fontChangelingWoff2}") format("woff2"),
    /* chrome、firefox */
    url("${fontChangelingWoff}") format("woff"),
    /* chrome、firefox */
    url("${fontChangelingTruetype}") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("${fontChangelingSvg}#Changeling Neo W01 Bold") format("svg");
  /* iOS 4.1- */
}
${(props) => css`
@font-face {
  font-family: "DefaultFont";
  src: url("${props.customization.defaultFontOtf}");
}

@font-face {
  font-family: "TitleFont";
  src: url("${props.customization.titleFontOtf}");
}
`}

body {
  ${(props) => css`
    background-color: ${props.customization.backgroundColor || '#FFFFFF'};
  `}
  font-family: "DefaultFont", Arial, Helvetica, Verdana, Geneva, Tahoma, sans-serif;
}

/* width */
body::-webkit-scrollbar {
  width: 10px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
  ${(props) => css`
  background: ${props.customization.navbarBackground || 'black'};
  `}
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  ${(props) => css`
  background: ${props.customization.navbarBackground || 'black'};
  `}
  opacity: 0.5;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "TitleFont", "DefaultFont", Arial, Helvetica, Verdana, Geneva, Tahoma, sans-serif;
  ${(props) => css`
  color: ${props.customization.fontColor || '#FFFFFF'};
  `}
}


span.error {
  color: #ffa799;
}

span.error::before {
  display: inline;
  content: "⚠ ";
}

.custom-button {
  ${(props) => css`
    background: ${props.customization.buttonBackgroundColor || '#000'};
    &:hover {
      background-color: ${shade(0.2, props.customization.buttonBackgroundColor || '#000')};
    }
  `}
  transition: background-color 0.2s;
}

button, a.action-button, .btn-primary {
  border: none;
${(props) => css`
  color: ${props.customization.buttonTextColor || '#FFFFFF'};
  background-color: ${props.customization.buttonBackgroundColor || '#004ea8'} !important;
`}
}

button:hover,
.btn-primary:hover,
.btn-primary.active,
.action-button:hover {
  border: none;
  ${(props) => css`
  color: ${props.customization.buttonTextColor || '#FFFFFF'};
  background-color: ${props.customization.buttonBackgroundColor || '#004ea8'};
`}
  opacity: 0.8;
}


${(props) => css`
${props.customization.customCss}
`}
`;

