import React, { ButtonHTMLAttributes, useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner, MessageBox, MessageList } from './style';
import Button from '../Button';
import * as Yup from 'yup';
import moment from 'moment';
import getValidationErrors from '../../utils/getValidationErrors';
import { FiMessageCircle } from 'react-icons/fi';
import { useForm } from "react-hook-form";
import api from "../../services/api";
import { useTranslation } from 'react-i18next';
import { asEnumerable } from 'linq-es2015';
import { Picker } from 'emoji-mart';
import Image from './../Image';
import profilePicture from '../../assets/images/profile-picture.png';
import ProfileMatchList from './List';
import ProfileMatchConversation from './Conversation';

interface Props {
  user: any;
  channelId: string
};

interface IProfileMatch {
  id: string,
  channelId: string,
  eventId: string,
  createdAt: number,
  messages: IProfileMatchMessage[],
  userNames: any[],
  users: []
};

interface IProfileMatchMessage {
  uid: string,
  userName: string,
  userId: string,
  content: string,
  createdAt: number
};

// var ref = rootRef.child("chat");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface ProfileMatchData {
  message: string;
}

const ProfileMatch: React.FC<Props> = (props) => {
  const [chat$, setProfileMatch$] = useState<IProfileMatch>();


  const currentChat = asEnumerable(chat$?.userNames || []).SingleOrDefault(a => a.id != props.user.id);

  return (
    <Conteiner id="profile-match" className="tab-pane fade out">
      <ul className="nav nav-tabs" id="myTab" role="tablist" style={{ padding: '5px', paddingBottom: '0px' }}>
        <li className={"nav-item " + (!chat$ ? 'active' : '')} role="presentation">
          <a onClick={() => {
            setProfileMatch$(undefined);
          }} className="nav-link " id="participants-tab" data-toggle="tab" href="#participants" role="tab" aria-controls="participants" aria-selected="true">Participantes</a>
        </li>
        {
          !!chat$ && <li className={"nav-item " + (!!chat$ ? 'active' : '')} role="presentation">
            <a className="nav-link" id="contacts-tab" data-toggle="tab" role="tab" aria-controls="contacts" aria-selected="false">{currentChat?.name || (currentChat?.fields || {})["Nome e sobrenome"]}</a>
          </li>
        }
      </ul>
      {!!chat$ && <ProfileMatchConversation {...props} chat={chat$}></ProfileMatchConversation>}
      {!chat$ && <ProfileMatchList {...props} startConversation={(chat: any) => { setProfileMatch$(chat); }}></ProfileMatchList>}
    </Conteiner>
  );
};

export default ProfileMatch;
