import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

import mainBackground from '../../assets/images/background.png';
import mobileBackground from '../../assets/images/backgroundMobile.png';


interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`
/* geral */

img {
  max-width: 100%;
}

${(props) => css`
color: ${props.customization.fontColor || '#333'};
`}

button, a.action-button {
${(props) => css`
  color: ${props.customization.buttonTextColor || '#FFFFFF'};
  background-color: ${props.customization.buttonBackgroundColor || '#004ea8'};
`}
}

a.link {
  ${(props) => css`
  color: ${props.customization.fontColor || '#333'};
  `}
}

form {
  margin: 80px 0;
  width: 340px;
  text-align: center;

  input[type='text'],
  input[type='number'],
  input[type='email'],
  input[type='password'],
  select {
    ${(props) => css`
    background-color: ${props.customization.inputBackgroundColor || '#FFF'};
    `}
    border: none;
    padding: 10px;
    width: 100%;
    margin: 3px;
    ${(props) => css`
    color: ${props.customization.inputTextColor || '#333'};
    `}
  }
}

.clear {
  clear: both;
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}

/* geral END */

main {
  height: 100vh;
  ${(props) => css`
  background: ${props.customization.background || `url(${mainBackground})`};
  `}
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}
aside h2 {
  font-size: 35px;
}

aside p {
  margin-bottom: 60px;
  font-size: 20px;
}

.ou {
  margin: 30px 0;
  display: block;
  font-size: 20px;
}

aside {
  ${(props) => css`
  background: ${props.customization.sideBarBackground || 'rgba(50, 141, 221, 0.4)'};
  `}
  min-height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .content{
  position: relative;
  top: 50%;
  transform: translate(0,-60%);
} */

article {
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
article h1 {
  color: #808080;
  font-size: 40px;
}

footer img {
  position: fixed;
  bottom: 0px !important;
}

.action-button {
  background: #004ea8;
  border: none;
  padding: 10px 40px;
  border-radius: 4px;
  color: white;
}

.link {
  color: black;
}

@media screen and (max-width: 767px) {
  /* geral mobile */

  img {
    max-width: 100%;
  }

  .clear {
    clear: both;
  }

  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }

  /* geral mobile END */
  main {
    ${(props) => css`
    background: ${props.customization.backgroundMobile || props.customization.background || `url(${mobileBackground})`};
    `}
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  article {
    margin-top: 147px;
    height: auto;
  }
  article img {
    width: 174px;
    height: 115px;
  }
  article h1 {
    font-size: 25px;
  }
  aside h2 {
    font-size: 35px;
  }
  aside {
    background-color: transparent !important;
    background-color: rgba(50, 141, 221, 0.4);
    height: 56vh;
    text-align: center;
    display: block;
  }
}
`;
