import React, { useEffect, useState } from 'react';
import { Conteiner } from './style';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';

const Languages: React.FC<any> = () => {
  const eventContext = useEvent();
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    localStorage.getItem('defaultLanguage') || 'pt'
  );

  function handleLanguageChange(language: string) {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    localStorage.setItem('defaultLanguage', language);
  }

  return (
    <>
      {eventContext.event?.langs?.length > 1 && (
        <Conteiner>
          {eventContext.event.langs.map((lang: string, index: number) => (
            <label key={index} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '5px 0' }}>
              <input
                type="checkbox"
                checked={selectedLanguage === lang}
                onChange={() => handleLanguageChange(lang)}
                style={{ marginRight: 10 }}
              />
              {lang === 'pt' && 'Português'}
              {lang === 'es' && 'Español'}
              {lang === 'en' && 'English'}
            </label>
          ))}
        </Conteiner>
      )}
    </>
  );
};

export default Languages;
