let config: any = {};

//if (document.location.href.indexOf('localhost') == -1) {
  config = {
    apiKey: "AIzaSyBUKuraIRTi0msI7yVKmPHPDaw0QCGmIeo",
    authDomain: "antonicelli-official.firebaseapp.com",
    databaseURL: "https://antonicelli-official.firebaseio.com",
    projectId: "antonicelli-official",
    storageBucket: "antonicelli-official.appspot.com",
    messagingSenderId: "384201856923",
    appId: "1:384201856923:web:6a3543cd83e9a9878cf33e",
    measurementId: "G-L2J57TM152"
  }
// } else {
//   config = {
//     apiKey: "AIzaSyCQIUQCFDgdCzKHD-6UBVz4UWYOIGC8mZY",
//     authDomain: "antonicelli-develop.firebaseapp.com",
//     databaseURL: "https://antonicelli-develop.firebaseio.com",
//     projectId: "antonicelli-develop",
//     storageBucket: "antonicelli-develop.appspot.com",
//     messagingSenderId: "570605146673",
//     appId: "1:570605146673:web:b04747df71ebb8f479bb10",
//     measurementId: "G-E4Z3EX35PB"
//   };
// }

export default config;
