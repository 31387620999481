import styled from 'styled-components';
import { shade } from 'polished';

export const Conteiner = styled.div`
  p {
    font-size: 13px;
  }
`;

export const MessageList = styled.div`
  height: 350px;
  padding: 10px;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #000000;

  p {
    /* border-bottom: 1px solid #DDD;
    padding: 5px; */
    position: relative;

    i {
      font-size: 8px;
      bottom: -10px;
      right: 10px;
      position: absolute;
    }
  }
`;

export const MessageBox = styled.div`
  padding: 10px;
  background-color: #fff;
  color: #000000;
  p {
    color: gray;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 0;
  }

  .emojiButton {
    border: none;
    padding: 6px;
    cursor: pointer;
  }

  input {
    margin-left: 10px;
    width: 100%;
    border: none;
    padding: 5px;
    border: 1px solid lightblue;
  }

  button {
    border: none;
    background: transparent;
  }

  p:last-child {
    text-align: right !important;
    margin-top: 10px;
  }

  i {
    font-size: 20px;
    color: lightblue;
    margin-left: 10px;
  }

`;
