import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Container } from './styles'
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import firebase from 'firebase/app';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';

const Help: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { signOut, getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    firebase.storage().refFromURL(`gs://antonicelli-official.appspot.com/companies/${props.params.event}/files`).listAll().then((docs) => {
      let items:any[] = [];

      for (const item of docs.items) {
        items.push({
          name: item.name
        })
      }

      setFiles(items);

      setTimeout(async () => {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const storeElement = docs.items[i];
          const downloadURL = await storeElement.getDownloadURL();
          const metadata = await storeElement.getMetadata();
          item.metadata = metadata;
          item.downloadURL = downloadURL;
        }

        setFiles([...items]);
      });
    });
  }, [props.params.event])

  return (
    <DashboardMasterPage {...props}>

      <Container customization={eventContext.event?.customization || {}}>
        <div className="container" style={{ marginTop: '62px' }}>
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse1">
                Onde eu encontro a agenda do evento?
              </a>
            </div>
            <div id="collapse1" className="collapse box-content">
              Acesse o menu agenda, lá você terá acesso à programação do evento todo.
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse2">
                Entrei no evento, mas a tela de exibição está muito pequena, o que eu faço?
              </a>
            </div>

            <div id="collapse2" className="collapse box-content">
              Na tela, ao lado do botão play, você vai ver um ícone de expandir a tela, é só clicar no ícone e
              automaticamente assistirá em tela cheia.
            </div>
          </div>



          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse3">
                Não aparece o ícone de expandir a tela, o que eu devo fazer?
              </a>
            </div>

            <div id="collapse3" className="collapse box-content">
              Isso acontece em alguns navegadores, verifique qual navegador está usando e tente trocar por outro.
              Recomendamos o uso do Google Chrome para não ter nenhum tipo de incompatibilidade.
            </div>
          </div>


          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse4">
                Desde que começou o evento, estou na mesma página, e parece que travou, o que eu faço?
              </a>
            </div>

            <div id="collapse4" className="collapse box-content">
              Pode ser que sua sessão tenha encerrado por você ter ficado muito tempo sem atividade. Aperte F5.
            </div>
          </div>



          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse5">
                Fui responder a enquete, mas apareceu a mensagem que tinha sido encerrada, por que isso acontece?
              </a>
            </div>

            <div id="collapse5" className="collapse box-content">
              A enquete tem um limite de tempo para resposta, após esse tempo não é mais possível responder.

            </div>
          </div>


          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse6">
                Durante o evento, o play parou de funcionar, o que eu faço?
              </a>
            </div>

            <div id="collapse6" className="collapse box-content">
              Verifique a conexão da sua internet. Se for um problema técnico da transmissão, avisaremos imediatamente a
              todos.
            </div>
          </div>

          <div className="card">
            <div className="card-header">
              <a className="box-header" type="button" data-toggle="collapse" data-target="#collapse7">
                Ainda tenho dúvidas de como participar e interagir durante o evento, com quem posso falar?
              </a>
            </div>
            <div id="collapse7" className="collapse box-content">
              Fale com o nosso suporte, no menu ajuda, pelo e-mail suporte@antonicelli.com.br ou pelo whatsapp 11
              97699-9574. Tenha um excelente evento!
            </div>
          </div>
        </div>
        <div className="clear"></div>
        </div>

      </Container>
    </DashboardMasterPage>
  );
};

export default Help;
