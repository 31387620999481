import React, { useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiLock } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import { useToast } from '../../hooks/Toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { useTranslation } from 'react-i18next';
import { AnimationContainer } from './styles';
import api from '../../services/api';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';

interface ResetPasswordFormData {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = (props: any) => {
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation();
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          password: Yup.string().required(t("Password is required")),
          password_confirmation: Yup.string().oneOf(
            [Yup.ref('password'), null],
            t('Invalid confirmation'),
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, password_confirmation } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        await api.post('participant/change-password', {
          password,
          password_confirmation,
          token,
        });

        history.push(`/${props.params.event}/signin`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          type: 'error',
          title: 'Erro resetar sua senha',
          description: 'Ocorreu um erro ao resetar sua senha, tente novamente.',
        });
      }
    },
    [addToast, history, location.search],
  );

  return (
    <DefaultMasterPage {...props}>
      <AnimationContainer>
        <Form className="reset-password-form" ref={formRef} onSubmit={handleSubmit}>
          <h1>{t('Recovery Password')}</h1>

          <Input
            name="password"
            icon={FiLock}
            type="password"
            placeholder={t('Password')}
          />

          <Input
            name="password_confirmation"
            icon={FiLock}
            type="password"
            placeholder={t('Password Confirmation')}
          />

          <Button type="submit">{t('Change my password')}</Button>
        </Form>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default ResetPassword;
