import React, { ButtonHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Conteiner } from './style';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({ children, loading, ...rest }) => {
  const { t } = useTranslation();


  return (
    <Conteiner className="custom-buttom" type="button" {...rest} disabled={loading}>
      {loading ? t('Loading') : children}
    </Conteiner>
  );
};

export default Button;
