import React from 'react';
import enus from "../assets/locales/en-us.json";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ptbr from "../assets/locales/pt-br.json";
import eses from "../assets/locales/es-es.json";

const I18Provider: React.FC = ({ children }) => {

  const lang = localStorage.getItem('defaultLanguage') || `pt`;

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        pt: ptbr,
        en: enus,
        es: eses
      },
      fallbackLng: [lang],
      lng: lang,
      interpolation: {
        escapeValue: false
      }
    });

  return <>
    {children}
  </>;
};

export { I18Provider };
