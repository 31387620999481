import React, { useEffect } from 'react';
import { useEvent } from '../../hooks/EventContext';

import { useAuth } from '../../hooks/FirebaseAuth';
interface SignInFormData {
  email: string;
  password: string;
}

const LogOut: React.FC = (props: any) => {
  const { signOut } = useAuth();
  const eventContext = useEvent();

  eventContext.loadEvent(props?.params?.event?.toLowerCase());

  useEffect(() => {
    if (eventContext.event) {
      signOut();

      setTimeout(() => {
        if (eventContext.event?.settings?.adfsUrl) {
          document.location.href = eventContext.event?.settings?.adfsUrl;

          localStorage.removeItem('@Storage:token');
          localStorage.removeItem('@Storage:user');
          localStorage.removeItem('@Storage:usertoken');
        }
      }, 3000);
    }

  }, [eventContext.event]);
  return <></>;
};

export default LogOut;
