import 'firebase/firestore';

import React, { createContext, useContext, useEffect, useState } from 'react';

import GlobalStyle from '../styles/global';
import api from '../services/api';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useToast } from './Toast';
import { useTranslation } from 'react-i18next';

const EventContext = createContext<EventData>({} as EventData);

export interface EventData {
  eventKey: string;
  event: any;
  channel: any;
  channels: any[];
  speakers: any[];
  chat: any;

  loadEvent(eventKey: string): void;
  loadInfo(eventKey: string, channelKey?: string): void;
}

const EventProvider: React.FC = (props: any) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [eventKey, setEventKey] = useState("");
  const [channelKey, setChannelKey] = useState("");
  const [event, setEvent] = useState<any>(null);
  const [channel, setChannel] = useState<any>(null);
  const [channels, setChannels] = useState<any[]>([]);
  const [speakers, setSpeakers] = useState<any[]>([]);
  const [chat, setChat] = useState<any>(null);
  const { addToast } = useToast();

  const loadEvent = async (key: string) => {
    key = key.toLowerCase();

    if (key != eventKey) {
      setEventKey(key);
      console.log(key);

      try {
        let result: any = null;

        try { api.get("/event/public-info", { params: { "key": key } }); } catch { }

        try {
          const query = await firebase.firestore().collection('event').where('key', '==', key.toLowerCase()).get();
          if (query.docs.length) {
            result = { data: query.docs[0].data() };
          }
          else {
            result = await api.get("/event/public-info", { params: { "key": key.toLowerCase() } });
          }
        } catch (error) {
          addToast({
            type: 'success',
            title: 'Aguarde...',
            description: 'Estamos verificando conexão com o servidor, aguarde...',
          });
          await new Promise((resolve) => { setTimeout(resolve, 15000) });
        }

        if (!result) {
          try {
            result = await api.get("/event/public-info", { params: { "key": key } });
          } catch (error) {
            result = null;
          }
        }

        if (result?.data) {
          document.title = result.data.name;
          setEvent(result.data);
          console.log(result.data);
        }
        else {
          addToast({
            type: 'error',
            title: 'Link não encontrado',
            description: 'Tente acessar novamente mais targe',
          });
        }

        result = null;
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Link não encontrado',
          description: 'Tente acessar novamente mais targe',
        });
      }

    }
  }

  const loadInfo = async (key: string, cKey: string = "") => {
    if (eventKey != key || channelKey != cKey || !channel) {

      setChannel({});
      setEventKey(key);
      setChannelKey(cKey);

      try {
        const query = await firebase.firestore().collection('event').where('key', '==', key.toLowerCase()).get();
        if (query.docs.length) {
          const result = { data: query.docs[0].data() };
          document.title = result.data.name;
          setEvent(result.data);
        }
      } catch (error) { }

      try {
        try {
          firebase.firestore().collection('event').where('key', '==', key.toLowerCase()).get().then((snapshot) => {
            const docs = snapshot.docs.map((doc) => { return doc.data() });
            if (docs.length) {
              const event = docs[0];


              if (event?.langs?.length === 1) {
                // only 1 lang
                i18n.changeLanguage(event.langs[0]);
                localStorage.setItem('defaultLanguage', event.langs[0]);
              }

              setEvent(event);
              document.title = event.name;

              firebase.firestore().collection('channel').where('eventId', '==', event.id).get().then((snapshot) => {
                let docs = snapshot.docs.map((doc) => { return doc.data() });

                // TODO: I cant change this because firebase saves these (no access rn)
                let languageKey = localStorage.getItem("defaultLanguage");

                const channel = asEnumerable(docs).FirstOrDefault(a => a.key == cKey) ||
                  asEnumerable(docs).FirstOrDefault(a => a.key == cKey || a.lang === languageKey) ||
                  asEnumerable(docs).FirstOrDefault(a => a.default) ||
                  asEnumerable(docs).First();
                setChannel(channel);

                if (channel.lang && asEnumerable(event?.links || []).Any((l: any) => l.url === 'channels')) {
                  i18n.changeLanguage(channel.lang);
                  localStorage.setItem('defaultLanguage', channel.lang);
                }

                docs = asEnumerable(docs).Where((c: any) => !c?.settings?.nvl?.enabled).ToArray();
                if (docs.length) {
                  setChannels(docs);
                }

              }).catch((error: any) => {
                // if (error?.message?.toLowerCase().indexOf('insufficient permissions') > -1) {
                //   history.push(`/${key}/logout`);
                // }
              });
            }
          });


        } catch (error) {
        }

        let language = localStorage.getItem("defaultLanguage");

        api.get(`/event/info?eventKey=${key}&channelKey=${cKey}&lang=${language}`).then((result) => {
          if (result?.data && result.data.event) {
            if (result.data.event && !event) {
              setEvent(result.data.event);
              document.title = result.data.event.name;
            }

            // if (result.data.channel && !channel) {
            //   setChannel(result.data.channel);
            // }

            if (result.data.channels && !channels.length) {
              setChannels(result.data.channels);
            }

            if (result.data.chat) {
              setChat(result.data.chat);
            }
          }
        }).catch(async error => {
          if (error?.response?.status == 401) {
            history.push(`/${key}/logout`);
          }
          else {
            api.get(`/event/info?eventKey=${key}&channelKey=${cKey}`);
          }
        })
      } catch (error) {
        //history.push(`/${key}/logout`);
      }


    }
  }

  useEffect(() => {
    if (!!channel?.id) {
      const unsubscrible = firebase.firestore().collection('channel').doc(channel.id).onSnapshot((snapshot) => {

        const data = snapshot.data();

        if (!!data) {
          setChannel({ ...data, update: moment().valueOf() });
        }
      });

      return () => {
        unsubscrible();
      }
    }
  }, [channel?.id]);

  return (
    <>
      <EventContext.Provider value={{ eventKey, event, channel, loadEvent, loadInfo, channels, speakers, chat }}>
        {props.children}
      </EventContext.Provider>
      <GlobalStyle customization={event?.customization || {}} />
    </>
  );
};

function useEvent(): EventData {
  const context = useContext(EventContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { EventProvider, useEvent };
