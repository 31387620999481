import React, { useEffect } from 'react';
import { Container } from './styles';
import { useEvent } from '../../hooks/EventContext';

const DefaultMasterPage: React.FC = (props: any) => {

  const eventContext = useEvent();

  useEffect(() => {
    eventContext.loadEvent(props.params.event);
   console.log(localStorage.getItem('defaultLanguage'))

    if((localStorage.getItem('defaultLanguage') != 'pt')  && props.params.event == 'facpc-2022') {
      localStorage.setItem('defaultLanguage', 'pt');
        window.location.reload();
    }
  }, [props.params.event]);

  return (
    eventContext.event && (<Container customization={eventContext.event?.customization || {}}>
      <main className="main-login">
		  <div className="container-for-login-background" dangerouslySetInnerHTML={{ __html: eventContext.event?.customization?.customLoginBackground }}></div>{/* //! Denys adicionou */}
        <article className="col-md-7 col-sm-6">
          <div className="content customizationlogin-container">
    		  <div id="customizationlogin" dangerouslySetInnerHTML={{ __html: eventContext.event?.customization?.customHtmlLogin }}></div>{/* //! Denys adicionou */}
          </div>
        </article>
        <aside className="col-md-5 col-sm-6">
          {props.children}
        </aside> 
      </main>
    </Container>)
  );
};

export default DefaultMasterPage;
