import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Container } from './styles'
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import SocialNetwork from '../../components/SocialNetwork';
import firebase from 'firebase/app';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';

const Feed: React.FC = (props: any) => {
  const { t } = useTranslation();
  const { signOut, getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    firebase.storage().refFromURL(`gs://antonicelli-official.appspot.com/companies/${props.params.event}/files`).listAll().then((docs) => {
      let items: any[] = [];

      for (const item of docs.items) {
        items.push({
          name: item.name
        })
      }

      setFiles(items);

      setTimeout(async () => {
        for (let i = 0; i < items.length; i++) {
          const item = items[i];
          const storeElement = docs.items[i];
          const downloadURL = await storeElement.getDownloadURL();
          const metadata = await storeElement.getMetadata();
          item.metadata = metadata;
          item.downloadURL = downloadURL;
        }

        setFiles([...items]);
      });
    });
  }, [props.params.event])

  return (
    <DashboardMasterPage {...props}>

      <Container customization={eventContext.event?.customization || {}}>
        <SocialNetwork {...props}></SocialNetwork>
      </Container>
    </DashboardMasterPage>
  );
};

export default Feed;
