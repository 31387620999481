import styled, { css } from 'styled-components';

import { shade } from 'polished';

interface ContainerPros {
  customization: any;
}

export const Container = styled.div<ContainerPros>`

.dashboard-page {
  min-height: 800px;
}

.nav-tabs > li > a {
  background-color: #DDD;
  color: #888;
}

li > a.active {
  background-color: #FFFFFF;
}

.message-list
{
  height: 350px;
  padding: 10px;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
  color: black;
}

video {
  /* height: 540px; */
  width: 100%;
}

.action-button {
  background: #004ea8;
  border: none;
  padding: 10px 40px;
  border-radius: 4px;
  color: white;
}

textarea:focus,
input:focus {
  outline: none;
}
img {
  max-width: 100%;
}

/* bot */
#bot {
  border: 10px solid #004ea8;
  width: 350px;
  max-width: 100%;
  height: 450px;
  padding: 20px;
  position: fixed;
  right: 60px;
  top: 60px;
  background: white;

  opacity: 0;
  visibility: hidden;

  transition: 0.5s;
}
#bot textarea {
  border: 3px solid #004ea8;
  width: 100%;
  max-width: 100%;
  height: 140px;
  padding: 10px;
}
#bot h2 {
  color: #747474;
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 29px;
}

.resource-content {
  min-height: 460px;
  background: white;
  border: 1px solid #eae1e1;
  border-top: none;
}

.player {
  margin-top: 10px;
  height: 420px;
}

.chatEmbed body
{
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  .player {
    margin-bottom: 20px;
    height: 210px;
  }

  .resource-content {
    min-height: 210px;
  }
}

.bot-box {
  background-image: url("../images/bot/robo.png");
  background-repeat: no-repeat;
  background-position: right top;
}

#button-container {
  text-align: center;
}
#bot-button {
  margin: 20px auto;
  background: #004ea8;
  border: none;
  border-radius: 4px;
  padding: 8px 40px;
  color: white;
}
/* bot END */

#apresentadores {
  margin: 40px 0;
}
#apresentadores .col-md-4 {
  display: block;
}
#apresentadores .nome {
  font-size: 25px;
}
#apresentadores .descricao {
  ${(props) => css`
  color: ${props.customization.fontColor || '#1b4f88'} ;
  `}
  font-size: 15px;
  line-height: 18px;
}
#apresentadores img {
  float: left;
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border-radius: 50%;
  border: 5px solid wheat;
}
#bb {
  position: fixed;
  top: 57px;
  z-index: 10;
  min-width: 100%;
}

/* info */
.info p {
  font-size: 20px;
}
.info .nome {
  font-size: 25px;
}
.info .descricao {
  color: black;
  font-size: 20px;
  margin-top: 10px;
}
.info img {
  display: block;
  margin: auto;
}

.texto p {
  font-size: 15px;
  ${(props) => css`
  color: ${props.customization.fontColor || '#000000'};
  `};
  line-height: 18px;
  padding: 15px;
  overflow-y: auto;
  overflow-x: none;
  text-align: left;


}

article p {
  text-align: right;
}

#messages {
  display: flex;
  flex: 1;
  flex-direction: column;
}
/* box-message END */

.activities {
  padding-right: 0px;
}

.activities .title {
  ${(props) => css`
  background-color: ${props.customization.navbarBackground || 'black'};
  `};
  ${(props) => css`
  color: ${props.customization.fontColor || 'white'};
  `};
  padding: 5px;
  font-weight: bolder;
  text-align: center;
}

.activities .activity-list {
  overflow-y: scroll;
  height: 318px;
}

.activities .item {
  ${(props) => css`
  background-color: ${props.customization.fontColor || 'black'};
  `};
  ${(props) => css`
  color: ${props.customization.backgroundColor || 'white'};
  `};
  padding: 2px;
  margin-top: 2px;
  text-align: center;
}

.activities .item a {
  ${(props) => css`
  color: ${props.customization.backgroundColor || 'white'};
  `};
  font-size: 11px;
}

.partners img {
  margin: auto;
  display: block;
}

.pt, .en, .es {
  display: none;
}

${(props) => css`
  .${props.customization.language} {
    display: inherit;
  }
  `};
`;


export const QrCodes = styled.div<any>`
border-bottom: 3px solid white;
padding: 20px;
`;

export const QrCode = styled.div<any>`
  img{
    max-height: 200px;
    margin: auto;
    display: block;
  }
`;
