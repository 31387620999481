import { Link, useHistory } from 'react-router-dom';

import { AnimationContainer } from './styles';
import DefaultMasterPage from '../../masterpages/DefaultMasterPage';
import Languages from '../../components/Languages';
import React from 'react';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowRight } from "react-icons/ai";
const Home: React.FC = (props: any) => {
  const { t } = useTranslation();
  const eventContext = useEvent();
  const auth = useAuth();
  const user = auth.getUser();
  const history = useHistory();

  if (user?.id) {
    history.replace(`/${props.params.event?.toLowerCase()}/dashboard`);
  }

  if (eventContext?.event?.settings?.noLogin) {
    history.push(`/${eventContext.eventKey?.toLowerCase()}/signin`);
  }

  return (
    <DefaultMasterPage {...props}>
      {/* <div id="customizationlogin" dangerouslySetInnerHTML={{ __html: eventContext.event?.customization?.customHtmlLogin }}></div> //! Denys comentou*/}

      <AnimationContainer>
        <div className="content">

          <div className="pre-home-access-your-account"></div>
          <h2 className="home-access-your-account">{eventContext?.event?.settings?.texts?.homeTitle || t('Access your account')}</h2>

          <div className="pre-home-languages"></div>
          <Languages></Languages>

          <div className="pre-home-text-login"></div>
          <p className="home-text-login" translate='no'>{eventContext?.event?.settings?.texts?.homeDescription || t('Faça login ou crie uma conta para acessar')}</p>

          <div className="pre-home-enter"></div>
          <Link to={`/${eventContext.eventKey}/signin`} className="action-button home-enter">
            {eventContext?.event?.settings?.texts?.homeSignIn || t('Sign In')}
          </Link>

          {/* <Link to={`/${eventContext.eventKey}/signin`} >
            <button style = {{ height: "35px, padding: 20px"}} className='btn-primary'>
              <p>ENTRAR <span className='icon2'>
                <AiOutlineArrowRight /></span>
              </p>
            </button>
          </Link> */}
          <div className="pre-home-ou"></div>


          <span className="ou home-ou">{t('or')}</span>

          <div className="pre-home-create-a-new-account"></div>

          {/* <Link to={`/${eventContext.eventKey}/signup`} ><button className='btn-primary'>
            <p>CRIE SUA CONTA
              <span className='icon'><AiOutlineArrowRight /></span>
            </p>
          </button>
          </Link> */}
          <Link to={`/${eventContext.eventKey}/signup`} className="action-button home-create-a-new-account">
            {eventContext?.event?.settings?.texts?.homeSignUp || t('Create a new account')}
          </Link>

          <br />

          <div style={{ paddingLeft: "20%", paddingRight: "20%", marginTop: "15px" }}>
            {eventContext?.event?.settings?.texts?.homeFooter}
          </div>

          <div>
            {!!eventContext.event?.customization?.signInImg && <img style={{ paddingTop: 60 }} src={eventContext.event?.customization?.signInImg}></img>}
          </div>
        </div>
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default Home;
