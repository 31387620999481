import 'firebase/firestore';

import * as Yup from 'yup';

import React, { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';

import { Conteiner } from './style';
import { Util } from '../../utils/util';
import api from "../../services/api";
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import { useEvent } from '../../hooks/EventContext';
import { useForm } from "react-hook-form";
import { useToast } from '../../hooks/Toast';
import { useTranslation } from 'react-i18next';

interface SurveyProps {
  user: any;
  channelId: string;
};

interface ISurveyQuestion {
  id: string;
  channelId: string;
  eventId: string;
  index: number;
  responseIndex: number;
  question: string;
  active: boolean;
  options: { id: string, text: string, index: number }[];
  user: {
    email: string,
    id: string
  };
}

// var ref = rootRef.child("survey");
// ref.on('value').then(function (snapshot) {
//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var childData = childSnapshot.val();
//   });
// });

interface IResponseData {
  index: number;
}

let responsesObj: any = {};

const Survey: React.FC<SurveyProps> = (props) => {
  const { t } = useTranslation();
  const { addToast } = useToast();
  const { register, unregister, setValue, handleSubmit, errors, getValues } = useForm<IResponseData>();
  const firestore = firebase.firestore();
  const [counter, setCounter] = useState<number>(0);
  const [polls, setPolls] = useState<ISurveyQuestion[]>([] as ISurveyQuestion[]);
  const [responses, setResponses] = useState<any[]>([]);
  const [newResponsesObj, setNewResponsesObj] = useState<any>({});

  useEffect(() => {
    const unsubscribe = firestore.collection('survey_question').where('active', '==', true).where('channelId', '==', props.channelId).onSnapshot((snapshot) => {
      const docs = snapshot.docs.map((a) => {
        return { id: a.id, ...a.data() } as ISurveyQuestion;
      });
      const data = asEnumerable(docs).OrderByDescending((o: any) => o.createdAt).Select((survey: any, i) => {
        let count = 0;
        const total = asEnumerable(survey.options).Sum((d: any) => survey['count-' + (count++)] || 0);
        for (let index = 0; index < survey.options.length; index++) {
          const responseCount = survey['count-' + index] || 0;
          survey['percent-' + index] = ((responseCount / total) * 100).toFixed(2);
        }

        return survey;
      }).ToArray();

      if (data.length) {
        const element = window.document.getElementById('survey-tab');
        element?.click();
      }
      else {
        const element = window.document.getElementById('chat-tab');
        element?.click();
      }

      setPolls(data);
      verifyResponses(responses);
    });

    const unsubscribe2 = firestore.collection('survey_response').where('userId', '==', props.user.id).onSnapshot((snapshot) => {
      const docs = snapshot.docs.map((a) => {
        return { id: a.id, ...a.data() } as any;
      });

      setResponses(docs);
      verifyResponses(docs);
    });

    return () => {
      unsubscribe();
      unsubscribe2();
    }
  }, [props.channelId]);

  verifyResponses(responses);

  function verifyResponses(responses: any[]) {
    for (let item of responses) {
      try {
        setValue('option-' + item.surveyId, item.index.toString());
      } catch (error) {
      }

      if (responsesObj[item.surveyId] === undefined) {
        responsesObj[item.surveyId] = item.index;
        setNewResponsesObj(responsesObj);
      }
    }
  }

  async function sendFunctionMessage(surveyId: string, index: string) {
    const data = {
      channelId: props.channelId,
      surveyId: surveyId,
      index: index
    };

    let ref:any;

    // try 3x
    try {
      ref = await api.post("/survey/response", data);
    } catch (error) {
      try {
        await Util.sleep(4);
        ref = await api.post("/survey/response", data);
      } catch (error) {
        try {
          await Util.sleep(4);
          ref = await api.post("/survey/response", data);
        } catch (error) {
          throw error;
        }
      }
    }

    addToast({
      type: 'success',
      title: 'Resposta enviada com sucesso',
      description: 'Obrigado por sua participação',
    });
  }

  async function handleSendMessage(surveyId: string) {
    try {
      const data = {
        index: newResponsesObj[surveyId],
        surveyId
      }

      const schema = Yup.object().shape({
        [`index`]: Yup.string().required("É obrigatório a escolha de uma resposta!"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await sendFunctionMessage(data.surveyId, data.index);

    } catch (error) {
      addToast({
        type: 'error',
        title: 'Falha ao enviar resposta',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao fazer cadastro, tente novamente.',
      });
    }
  };

  function optionChange(surveyId: string, index: number) {
    let response = { ...newResponsesObj };
    response[surveyId] = index;
    setNewResponsesObj(response);
  }

  const eventContext = useEvent();

  return (
    <Conteiner id="survey" className={`chatbox tab-pane fade ${eventContext.channel?.resources?.length == 1 ? 'in active' : ''}`}>
      <fieldset className="form-group message-list" style={{ padding: '10px', height: 'auto', minHeight: '450px', maxHeight: '600px' }}>
        {
          polls.map((survey: any, index) => (
            <form key={index}>
              <span className="col-sm-12" style={{ padding: '10px' }}>{(polls.length - index) + ') ' + survey.question}</span>
              <div className="row" style={{ padding: '10px' }}>
                <div className="col-sm-12">
                  {
                    !survey.showResponses ?
                      survey.options?.map((option: any, i: number) => (
                        <div key={i} className="form-check">
                          <input checked={newResponsesObj[survey.id]?.toString() == i.toString() || responsesObj[survey.id]?.toString() == i.toString()} onChange={(event: any) => optionChange(survey.id, i)} id={`option-${i}-${index}`} className="form-check-input" type="radio" radioGroup={`${survey.id}`} name={`option-${survey.id}`} value={i} />
                          <label htmlFor={`option-${i}-${index}`} className="form-check-label">
                            &nbsp; {option.text}
                          </label>
                        </div>
                      )) : <>
                        {
                          survey.options?.map((option: any, i: number) => (
                            <React.Fragment key={i}>
                              <label className="form-check-label">
                                &nbsp; {option.text}
                              </label>
                              <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{ width: `${survey['percent-' + i]}%` }} aria-valuenow={survey['percent-' + i]} aria-valuemin={0} aria-valuemax={100}>{survey['percent-' + i]}%</div>
                              </div>
                            </React.Fragment>
                          ))
                        }
                      </>
                  }
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  {(responsesObj[survey.id] === undefined) && !survey.showResponses && <button type="button" onClick={() => handleSendMessage(survey.id)} className="btn btn-primary">Enviar resposta</button>}
                </div>
              </div>
            </form>
          ))
        }
      </fieldset>
    </Conteiner>
  );
};

export default Survey;
