import 'firebase/firestore';
import 'firebase/firebase-storage';

import * as Yup from 'yup';

import { Card, Form } from 'react-bootstrap';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Button from '../../Button';
import { Conteiner } from './style';
import { Modal } from 'antd';
import Spinner from 'react-bootstrap/Spinner';
import { asEnumerable } from 'linq-es2015';
import firebase from 'firebase/app';
import moment from 'moment';
import { useAuth } from '../../../hooks/FirebaseAuth';
import { useEvent } from '../../../hooks/EventContext';
import { useForm } from 'react-hook-form';
import { useToast } from '../../../hooks/Toast';

interface Props {
  user: any;
  channelId: string;
  eventId: string;
  item: any;
};

const Comment: React.FC<Props> = (props: any) => {
  const { register, setValue, handleSubmit, errors } = useForm<FormData>();
  const firestore = firebase.firestore();
  const { addToast } = useToast();
  const [lgShow, setLgShow] = useState(false);
  const [sending, isSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const eventContext = useEvent();
  const { getUser } = useAuth();
  const user = getUser();

  const onSubmit = handleSubmit(async (data: any) => {
    try {

      setLoading(true);

      const schema = Yup.object().shape(
        {
          userComment: Yup.string().required('O comentário é obrigatório')
        }
      );

      await schema.validate(data, {
        abortEarly: true,
      });


      const entity: any = {
        createdAt: moment.utc().valueOf(),
        comment: data.userComment,
        channelId: eventContext.channel?.id || "",
        eventId: eventContext.event.id,
        user: {
          name: user.name,
          email: user.email
        },
        uid: user.id
      };

      const ref = firestore.collection('feed').doc(props.item.id);
      await ref.update({
        comments: firebase.firestore.FieldValue.arrayUnion(entity)
      });

      setValue("userComment", '');
      setLgShow(false);

      addToast({
        type: 'success',
        title: 'Post enviado',
        description: 'Sua mensagem foi enviada com sucesso!',
      });

      setLoading(false);

    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro no envio',
        description: error?.response?.data?.message || error.message || 'Ocorreu um erro ao fazer o envio, tente novamente.',
      });
    }
  });

  return (
    <>
      {/* <Button onClick={() => setLgShow(true)}>Comentários ({props.item?.comments?.length})</Button> */}
      <a className="bloco-comentarios" onClick={() => setLgShow(true)}>
        <i className="fa fa-comment text-muted" aria-hidden="true" />
        <span>{props.item?.comments?.length}</span>
      </a>

      <Conteiner>

        <Modal
          visible={lgShow}
          footer={false}
          onCancel={() => { setLgShow(false) }}
        >
          <p>
            {props.item.user.name}<br />
            {props.item.text}
          </p>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Digite seu comentário</Form.Label>
              <Form.Control name="userComment" ref={register} as="textarea" rows={3} />
            </Form.Group>

            <Button type="submit" loading={loading}>
              Enviar <i className="fa fa-paper-plane"></i>
            </Button>

          </Form>
        </Modal>
      </Conteiner>
    </>
  );
};

export default Comment;
