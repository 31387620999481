import 'react-day-picker/lib/style.css';
import 'firebase/firestore';
import 'firebase/auth';

import { BsCardChecklist, BsChatDots, BsLink45Deg, BsQuestionSquare } from 'react-icons/bs';
import { Col, Row } from 'react-bootstrap';
import { Container, QrCode, QrCodes } from './styles'
import DayPicker, { DayModifiers } from 'react-day-picker';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { format, isAfter, isToday, parseISO } from 'date-fns';

import Channels from '../../components/Channels';
import Chat from '../../components/Chat';
import DashboardMasterPage from '../../masterpages/DashboardMasterPage';
import { FaUsers } from 'react-icons/all';
import { FcSurvey } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import Moderator from '../../components/Moderator';
import ProfileMatch from '../../components/ProfileMatch';
import Question from '../../components/Question';
import Survey from '../../components/Survey';
import api from '../../services/api';
import asEnumerable from 'linq-es2015';
import firebase, { firestore } from 'firebase/app';
import infoImage from '../../assets/images/video/AHsuQQC.png';
import ptBR from 'date-fns/locale/pt-BR';
import { useAuth } from '../../hooks/FirebaseAuth';
import { useEvent } from '../../hooks/EventContext';
import { useTranslation } from 'react-i18next';

const Dashboard: React.FC = (props: any) => {
  const { t, i18n } = useTranslation();
  const { signOut, getUser } = useAuth();
  const user = getUser();
  const eventContext = useEvent();
  const firestore = firebase.firestore();
  const [feed, setFeed] = useState<any[]>([]);
  const internalLogo = eventContext.event?.settings?.internalLogo;
  const dashboardLogo = eventContext.event?.customization?.dashboard?.logo;
  const strLogoUrl = dashboardLogo || eventContext.event?.customization?.logo;

  const bHasCustomLogo = internalLogo != null || dashboardLogo != null;

  useEffect(() => {
    // const unsubscribe = firestore.collection("feed").where('eventId', '==', eventContext.event.id).orderBy("createdAt", "desc").onSnapshot((snapshot) => {
    //   const items = snapshot.docs.map(a => { return { ...a.data(), id: a.id } });
    //   console.log(eventContext.event.id)
    //   setFeed(items);
    // });
    // return () => {
    //   unsubscribe();
    // }
  }, []);

  function like(feed: any) {
    const ref = firestore.collection("feed").doc(feed.id);
    feed.likesObj = feed.likesObj || {};

    if (!feed.likesObj[user.id]) {
      feed.likesObj = { ...feed.likesObj, [user.id]: true };
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(1),
        likesObj: feed.likesObj
      });
    }
    else {
      delete feed.likesObj[user.id];
      return ref.update({
        likes: firebase.firestore.FieldValue.increment(-1),
        likesObj: feed.likesObj
      });
    }
  }

  function getFooter() {
    return eventContext.event?.customization?.footer?.images &&
      <div id="streamingFooter" style={{ height: "10vw", marginTop: "30px", display: "flex", alignItems: "center", flexGrow: 1 }} >
        {eventContext.event.customization.footer.images.map &&
          eventContext.event.customization.footer.images.map((imgSrc: any, i: number) => {
            return <img key={`${i}sfooter`} src={imgSrc} style={{ flexGrow: 1, marginRight: "25px" }} />
          }) || eventContext.event.customization.footer.images[i18n.language] &&
          eventContext.event.customization.footer.images[i18n.language].map((imgSrc: any, i: number) => {
            if (imgSrc.img) {
              return <a target="blank" key={`${i}sfooter`} href={imgSrc.link} style={{ flexGrow: 1 }}>
                <img src={imgSrc.img} style={{ maxHeight: "10vw", flexGrow: 1, marginRight: "25px" }} />
              </a>;
            } else {
              return <img key={`${i}sfooter`} src={imgSrc} style={{ maxHeight: "10vw", flexGrow: 1, marginRight: "25px" }} />
            }
          })}
      </div>;
  }

  return (
    <DashboardMasterPage {...props}>

      <Container className={`${eventContext.event?.key?.toLowerCase()} ${eventContext.channel?.key?.toLowerCase()} ${i18n.language}`} customization={{ ...eventContext.event?.customization, language: i18n.language } || {}}>

        <div className="container dashboard-page" style={{ marginTop: '62px' }}>

          <div id="customizationtop" style={{ marginTop: '62px' }} dangerouslySetInnerHTML={{ __html: eventContext.event?.customization?.customHtmlTop }}></div>

          <main id="streaming" style={{}}>
            <div className={"col-md-" + (eventContext.channel?.resources?.length ? 8 : 12)}>
              <div className={!!((eventContext.channel?.description_languages || {})[i18n.language] || eventContext.channel?.description || eventContext.event?.customization?.customChannelHeaderImg) ? "col-md-6 desktop" : "col-md-12 desktop"}>
                {bHasCustomLogo && <a href={`/${props.params.event}`}>
                  <img height={100} src={strLogoUrl} />
                </a>}
                {!bHasCustomLogo && <h3>{t('This is')}</h3>}
                {!bHasCustomLogo && <h1>{(!!eventContext.channel?.name_languages ? eventContext.channel.name_languages[i18n.language] || '' : eventContext.channel?.name || '') || t("Loading")} </h1>}


              </div>
              {((eventContext.channel?.description_languages || {})[i18n.language] || eventContext.channel?.description || eventContext.event?.customization?.customChannelHeaderImg) && <div className="col-md-6 desktop">
                <p>
                  {!!eventContext.event?.customization?.customChannelHeaderImg ?
                    <img src={eventContext.event?.customization?.customChannelHeaderImg} /> :
                    eventContext.channel?.description || (eventContext.channel?.description_languages || {})[i18n.language]
                  }
                </p>
              </div>}
              <div className="col-md-6 mobile">
                {bHasCustomLogo && <a href={`/${props.params.event}`}>
                  <img height={100} src={strLogoUrl} />
                </a>}
                {!bHasCustomLogo && <h3>{t('This is')}</h3>}
                {!bHasCustomLogo && <h1>{(!!eventContext.channel?.name_languages ? eventContext.channel.name_languages[i18n.language] || '' : eventContext.channel?.name || '') || t("Loading")} </h1>}
              </div>
              {eventContext.channel?.description && <div className="col-md-6 mobile">
                <p>
                  {eventContext.channel?.description}
                </p>
              </div>}
              {
                user.role == 'moderator' ?
                  <Moderator user={user} channelId={eventContext.channel?.id} eventId={eventContext.event?.id}></Moderator>
                  :
                  (!!eventContext.channel?.videoUrl && <div>
                    <iframe className="player" src={`${eventContext.channel?.videoUrl}${eventContext.channel?.videoUrl?.indexOf('?') > -1 ? '&update=' + eventContext.channel?.update : '?update=' + eventContext.channel?.update}`} allow="autoplay; fullscreen" frameBorder={0} width="100%" >
                    </iframe>
                    {feed.map((item: any, i: number) => {
                      return <div style={{ position: 'absolute', width: 300, margin: 0, padding: 15, bottom: 0, right: 0, textAlign: 'right' }}>
                        <a style={{ marginRight: 10 }} onClick={() => like(item)}>
                          <i className="fa fa-heart text-danger" aria-hidden="true" />
                          <span className="text-muted">{item.likes || 0}</span>
                        </a>

                      </div>
                    })}
                  </div>)

              }

              {user.role != 'moderator' && getFooter()}


            </div>
            {eventContext.channel?.resources?.length > 0 && <div className="col-md-4" id="messages">
              <ul className="nav nav-tabs">
                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'chat') &&
                  <li className={`chat-tab active`}><a title={t('Chat')} data-toggle="tab" id={`chat-tab`} href={`#chat`}><BsChatDots color="black" /></a></li>
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'profile-match') &&
                  <li className={`profile-match-tab`}><a title={t('Users')} data-toggle="tab" id={`profile-match-tab`} href={`#profile-match`}><FaUsers color="black" /></a></li>
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'survey') &&
                  <li className={`survey-tab`}><a title={t('Survey')} data-toggle="tab" id={`survey-tab`} href={`#survey`}><BsCardChecklist color="black" /></a></li>
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'question') &&
                  <li className={`question-tab`}><a title={t('Question')} data-toggle="tab" id={`question-tab`} href={`#question`}><BsQuestionSquare color="black" /></a></li>
                }

                {/* {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'links') &&
                  <li className={`links-tab`}><a title={t('Links')} data-toggle="tab" id={`links-tab`} href={`#links`}><BsLink45Deg color="black" /></a></li>
                } */}

                {
                  props.params.event === 'brf-em-foco' &&
                  <li className={`links-tab`}>
                    <a title={t('Pesquisa')} target="_blank" href="https://forms.gle/iBV21eSreKFeBQKQA">
                      <BsLink45Deg color="black" />
                    </a>
                  </li>
                }

                {
                  props.params.event === 'brf-vp-operacoes' &&
                  <li className={`links-tab`}>
                    <a title={t('Pesquisa')} target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSeVOqSHCmjx6uJs7j93vG66Coi60Isu5PKrreb7xZQU8JbXLg/viewform">
                      <BsLink45Deg color="black" />
                    </a>
                  </li>
                }
              </ul>
              <div className="tab-content resource-content">
                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'chat') &&
                  !eventContext.channel?.settings?.vimeoChat && <Chat id="chat" className="tab-pane fade in active" {...props} user={user} channelId={eventContext.channel?.id} />
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'chat') &&
                  !!eventContext.channel?.settings?.vimeoChat && <div id="chat" className="tab-pane fade in active">
                    <iframe className="chatEmbed" src={eventContext.channel?.settings?.vimeoChat} style={{ width: '100%', padding: 5, overflow: 'hidden' }} height="470" frameBorder={0}></iframe>
                  </div>
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'profile-match') &&
                  <ProfileMatch {...props} user={user} channelId={eventContext.channel?.id} />
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'survey') &&
                  <Survey user={user} channelId={eventContext.channel?.id} />
                }

                {
                  asEnumerable(eventContext.channel?.resources || []).Any((r: any) => r.key == 'question') &&
                  <Question customization={eventContext.event?.customization || {}} user={user} eventId={eventContext.event?.id} channelId={eventContext.channel?.id} />
                }

                {
                  !!eventContext.channel?.settings?.links && <Col md={12} id="links" className="chatbox tab-pane fade">
                    <article>
                      <h3>{t('Links')}</h3>
                      {eventContext.channel?.settings?.links.map((link: any) => {
                        return <>
                          <a target="_blank" href={link.url}>{link.name}</a><br />
                        </>
                      })}
                    </article>
                  </Col>
                }
              </div>
            </div>}

          </main>



          {eventContext.channel?.settings?.kit?.enabled || !!eventContext.event?.customization?.infoImage && <article>
            <Row>

              <Col md={8} style={{ padding: '20px' }}>

                {
                  eventContext.channel?.settings?.kit?.enabled && <Col md={12}>
                    <article><a target="_blank" href={eventContext.channel.settings.kit.link}>
                      <img src={eventContext.channel.settings.kit.img} />
                    </a>
                    </article>
                  </Col>
                }
              </Col>
              <Col md={4}>
                {
                  !!eventContext.event?.customization?.infoImage && <Col className="info" md={12}>
                    <article><img src={eventContext.event?.customization?.infoImage} alt="" /></article>
                  </Col>
                }
              </Col>

            </Row>

          </article>}
          <div className="clear"></div>

          {!!eventContext?.channel?.speakers?.length && <div id="apresentadores">
            {asEnumerable(eventContext?.channel?.speakers || []).Select((speaker: any, i) => (
              <div key={i} className="col-md-4">
                <div className="people">
                  {!!speaker.picture && <img src={speaker.picture} alt="" />}
                  <h4 className="nome">
                    {speaker.name}
                  </h4>
                  <p className="descricao">
                    {speaker.description}
                  </p>
                </div>
              </div>
            )).ToArray()}
            <div className="clear"></div>
          </div>
          }

          <div className="clear"></div>

          {
            !!eventContext.channel?.settings?.qrCodes?.enabled && <Col md={12}>
              <article className="article-qrcodes">
                <QrCodes className="qrcodes" customization={eventContext.event?.customization || {}}>
                  {
                    eventContext.channel?.settings?.qrCodes?.list?.map((qrcode: any, i: number) =>
                      <QrCode key={i} className={"qrcode col-md-" + (12 / eventContext.channel?.settings?.qrCodes?.list?.length)} customization={eventContext.event?.customization || {}}>
                        {!!qrcode.link && <a target="_blank" href={qrcode.link}>
                          <img className="qrcode-img" src={qrcode.img} />
                        </a>}
                        {!qrcode.link && <img className="qrcode-img" src={qrcode.img} />
                        }
                      </QrCode>
                    )
                  }
                  <div style={{ clear: 'both' }}></div>
                </QrCodes>
              </article>
            </Col>
          }
          <div className="clear"></div>

          <Channels {...props} internalPage={true} />

          <div className="clear"></div>

          {!!eventContext.event?.customization?.partners && <div id="partners" className="partners">
            <img style={{ marginTop: 15 }} useMap="#partnersMap" src={eventContext.event?.customization?.partners[i18n.language] || eventContext.event?.customization?.partners} />
          </div>}

          {eventContext.event?.key == 'simposio-t21' && <div className="row" style={{ backgroundColor: 'white', borderTop: 'solid 10px #0095d9', margin: 10, padding: 10 }}>
            <h3 className="col-md-12" style={{ textAlign: 'center', color: 'black' }}>Patrocínio</h3>
            <a style={{ textAlign: 'center' }} className="col-md-4 col-xs-12" href="https://memnon.com.br/" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2FMemnon.png?alt=media&token=47a56a0b-5f02-47e9-8150-c641fc7233f3" /></a>
            <a style={{ textAlign: 'center' }} className="col-md-4 col-xs-12" href="https://www.aguarabelo.com.br" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2FLogo%20%C3%81gua%20Rabelo%20com%20dourado.png?alt=media&token=b9645547-1b92-489e-9cc3-93109dbd0e5f" /></a>
            <a style={{ textAlign: 'center' }} className="col-md-4 col-xs-12" href="http://www.boiron.com.br/" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2Fboiron.png?alt=media&token=db47acf0-28dd-402d-9a19-9412d614be5e" /></a>

            <h3 className="col-md-12" style={{ textAlign: 'center', color: 'black' }}>Apoio</h3>
            <a style={{ textAlign: 'center' }} className="col-md-3 col-xs-12" href="http://federacaodown.org.br/" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2Ffedera%C3%A7%C3%A3o%20brasileira.png?alt=media&token=2ce8d27b-6b0a-4224-a0f6-76a881b4e1a9" /></a>
            <a style={{ textAlign: 'center' }} className="col-md-3 col-xs-12" href="https://antonicelli.com.br" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2FlogoAC_preto.png?alt=media&token=e6adcb11-44ad-4729-8145-faba461b70b4" /></a>
            <a style={{ textAlign: 'center' }} className="col-md-3 col-xs-12" href="https://www.instagram.com/idooc.app/" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2Fidooc.png?alt=media&token=b20045f8-97d7-4b0d-8dd4-61b0b9f40f52" /></a>
            <a style={{ textAlign: 'center' }} className="col-md-3 col-xs-12" href="http://www.fmabc.br/" target="_blank"><img src="https://firebasestorage.googleapis.com/v0/b/antonicelli-official.appspot.com/o/companies%2Fsimposio-t21%2Fcustomization%2Ffmabc_novo_logo.png?alt=media&token=c5e20203-23c0-4b87-978c-f7d63840df41" /></a>
          </div>}

          {/* {!!eventContext.event?.customization?.container1 && eventContext.event?.customization?.container1[i18n.language] && <div id="container1" className="container1">
            <img style={{ marginTop: 15 }} src={eventContext.event?.customization?.container1[i18n.language] || eventContext.event?.customization?.container1} />
          </div>}

          {!!eventContext.event?.customization?.container2 && eventContext.event?.customization?.container2[i18n.language] && <div id="container2" className="container2">
            <img style={{ marginTop: 15 }} src={eventContext.event?.customization?.container2[i18n.language] || eventContext.event?.customization?.container2} />
          </div>}

          {!!eventContext.event?.customization?.container3 && eventContext.event?.customization?.container3[i18n.language] && <div id="container3" className="container3">
            <img style={{ marginTop: 15 }} src={eventContext.event?.customization?.container3[i18n.language] || eventContext.event?.customization?.container3} />
          </div>}

          {!!eventContext.event?.customization?.container4 && eventContext.event?.customization?.container4[i18n.language] && <div id="container4" className="container4">
            <img style={{ marginTop: 15 }} src={eventContext.event?.customization?.container4[i18n.language] || eventContext.event?.customization?.container4} />
        </div>} */}

          <div className="clear"></div>
          <div id="customization" dangerouslySetInnerHTML={{ __html: eventContext.event?.customization?.customHtml }}></div>
        </div>

        {user.role == 'moderator' && getFooter()}
      </Container>


    </DashboardMasterPage >
  );
};

export default Dashboard;
