import React, { Component, useState } from 'react';

interface Props {
  src: string;
  default: string;
}

const Image: React.FC<any> = (props: any) => {
  const [img, setImg] = useState<any>();
  return (
    <img {...props} ref={img => setImg(img)} onError={
      () => {
        if (props.default) {
          img.src = props.default;
        }
      }
    } />
  );
}

export default Image;
