import { AnimationContainer } from './styles';
import DefaultMasterPage from '../../../masterpages/DefaultMasterPage';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Success: React.FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <DefaultMasterPage {...props}>
      <AnimationContainer>
        <h1 className="congratulations">Parabéns</h1>
        <p className="cadastro-realizado">
          Seu cadastro foi realizado com sucesso!<br /><br />
          Em breve você receberá um e-mail com a confirmação e todas as informações para acessar o evento.
        </p>
        <Link className="link" to={`/${props.params.event}/signin`}>
          {t('Back to Sign In')}
        </Link>
        {props.params.event.indexOf('brf-') > -1 && <img src='https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp=10222999&ea=lead&ev=sucesso' />}
      </AnimationContainer>
    </DefaultMasterPage>
  );
};

export default Success;
