import React, { createContext, useCallback, useContext, useState } from 'react';

import ToastContainer from '../components/ToastContainer';
import { v4 } from 'uuid';

export interface ToastMessage {
  id: string;
  type?: 'success' | 'error' | 'info';
  title: string;
  description?: string;
  seconds?: number;
}

interface ToastData {
  addToast(message: Omit<ToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const Toast = createContext<ToastData>({} as ToastData);

const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastMessage[]>([]);

  const addToast = useCallback(
    ({ type, title, description, seconds }: Omit<ToastMessage, 'id'>) => {
      const id = v4();
      const toast = {
        id,
        type,
        title,
        description,
        seconds
      };

      setMessages((state) => [...state, toast]);
    },
    [],
  );
  const removeToast = useCallback((id: string) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <Toast.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </Toast.Provider>
  );
};

function useToast(): ToastData {
  const context = useContext(Toast);

  if (!context) {
    throw new Error('UseToast must be used within a ToastProvider');
  }
  return context;
}

export { ToastProvider, useToast };
