import React, { useEffect, useState, useRef, useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Conteiner } from './style';
import * as Yup from 'yup';
import moment from 'moment';
import { useToast } from '../../../hooks/Toast';
import { useForm } from "react-hook-form";
import api from "../../../services/api";
import { Link, useHistory } from 'react-router-dom';

interface ChatProps {
  user: any;
  channelId: string
  eventId: string
};

const Questions: React.FC<ChatProps> = (props: any) => {
  const firestore = firebase.firestore();
  const [questions, setQuestions] = useState<any[]>([]);
  const { addToast } = useToast();

  useEffect(() => {
    if (props.channelId) {
      let unsubscribe: any = null;
      unsubscribe = firestore.collection('question').orderBy('createdAt', 'desc').where('channelId', '==', props.channelId).onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((a) => {
          return { id: a.id, ...a.data() };
        });
        setQuestions(docs);

      });
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      }
    }
  }, [props.channelId]);

  function markAsAnswered(data: any) {
    const ref = firestore.collection('question').doc(data.id);
    ref.update({
      answered: !data.answered
    });
  }

  return (
    <Conteiner>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nome / Email / Data / Pergunta</th>
            <th scope="col">Respondida</th>
            <th scope="col">Edição</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((s: any, index1: number) => (
            <tr key={index1}>
              <th scope="row">{questions.length - index1}</th>
              <td><b>{s.name && <>{s.name}<br /></>}{s.email}<br/></b>{moment(s.createdAt).format('YYYY-MM-DD HH:mm:ss')}<br />{s.question}</td>
              <td>{s.answered ? 'Sim' : 'Não'}</td>
              <td>
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Atualizar
                      </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" onClick={() => markAsAnswered(s)}>Marcar como respondida</a>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Conteiner>
  );
};

export default Questions;
